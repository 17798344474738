import { useState } from "react";
import API from '../../config/API';

const TestCICD = () => {

  const [state, setState] = useState({disabled:false});

  const cleanDB = () =>{
    const q = window.confirm("Are you sure?");

    if(q){
      setState({...state, disabled:true});

      API.post('/v3/api/utils/cleanDB', {}).then(e=>{
        if(e.status){
          setState({...state, disabled:false, alert:'success', message:'The database was cleaned correctly.'});
        }else{
          setState({...state, disabled:false, alert:'danger', message:'An error occurred.'});
        }
      });

    }
  }

  return (<div>
    <h2>Test CI/CD</h2>
    {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
    <br/>
    <button className="btn btn-success" onClick={cleanDB} disabled={state.disabled}>CleanDB (testuser@anyverse.ai)</button>
  </div>);

}

export default TestCICD;
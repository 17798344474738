import { Fragment, useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';
import Editor from '../../../helpers/jsonviewer';

const LogRequestByTime = () => {

  const [state, setState] = useState({
      status: "loaded",
      search: null,
      requests:[],
      error: null,
      filter: {
        type: 'all',
        severity: 'all'
      },
      since: (new Date( (+new Date())-86400000 ).toISOString().slice(0,10)), // yesterday
      to: (new Date().toISOString().slice(0,10)),
      include_input: "",
      exclude_input: "",
      includes: [],
      excludes: []
    });

  const [waiting, setWaiting] = useState(false);

  const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data.raw, null, 2)}</pre>;

  const filterData = () => {

    return state.requests.filter(e=>{
      
      if( state.filter.type !== 'all' && !e.logName.includes(state.filter.type) ){
        return false;
      }

      if( state.filter.severity !== 'all' && e.severity.toLowerCase() !== state.filter.severity ){
        return false;
      }

      return true;

    }).map(e=>{

      let payload;
      if(e.logName.includes('stdout') || e.logName.includes('stderr')){ payload=e?.textPayload; }
      else if(e.logName.includes('request')){ payload=e?.httpRequest?.requestMethod+" "+e?.httpRequest?.requestUrl; }
      else{payload='';}

      const time = ( parseInt(e.httpRequest.latency.seconds) + (parseInt(e.httpRequest.latency.nanos)/1e+9) ).toFixed(2);

      return {
        hhmm: new Date(e.timestamp.seconds*1000).toISOString().substring(11,19),
        size: formatBytes(e.httpRequest.responseSize),
        payload,
        time,
        raw: e
      }

    });

  }

  function formatBytes(b) {
    const bytes = parseInt(b);
    if (bytes >= 1000000) {
        return (bytes / 1000000).toFixed(2) + ' MB';
    } else if (bytes >= 1000) {
        return (bytes / 1000).toFixed(2) + ' KB';
    } else {
        return bytes + ' Bytes';
    }
}


  const newSearch = () => {

    if(
      Number.isNaN(+new Date(state.since)) ||
      Number.isNaN(+new Date(state.to)) ){
      return;
    }

    setWaiting(true);

    API.post('/v3/api/utils/logs/slowRequest', {
      "since": (new Date(state.since+"T00:00:00Z").toISOString()),
      "to": (new Date(state.to+"T23:59:59Z").toISOString()),
      "search": state.includes,
      "notSearch": state.excludes,
      "ms": parseInt(state.search)
    }).then((data)=>{

      setWaiting(false);

      if( data?.logs ){
        setState({...state, requests: data.logs, error: null});
      }else{
        setState({...state, error: data});
      }

    });
  
  }

  const updateSince = (e) => setState({...state, since: e.target.value});

  const updateTo = (e) => setState({...state, to: e.target.value});

  const columns = [
    { name: 'HH:MM', selector: (row) =>  row.hhmm, sortable: true, grow: 0 },
    { name: 'Seconds', selector: (row) => row.time, sortable: true, grow: 0 },
    { name: 'Size', selector: (row) => row.size, sortable: true, grow: 0 },
    { name: 'Payload', selector: (row) => row.payload, sortable: true }
  ];

  const include = (action, value) => {

    if (action === 'add'){
      const value = state.include_input;

      if(value.length <= 1){ return; }
      if( state.includes.find(e=>e===value) !== undefined ){ return; };

      state.includes.push(value);

      setState({...state});
    }

    if(action === 'delete'){
      const newIncludes = state.includes.filter(e=>e!==value);
      setState({...state, includes: newIncludes});
    }

  }

  const exclude = (action, value) => {

    if (action === 'add'){
      const value = state.exclude_input;

      if(value.length <= 1){ return; }
      if( state.excludes.find(e=>e===value) !== undefined ){ return; };

      state.excludes.push(value);

      setState({...state});
    }

    if(action === 'delete'){
      const newExcludes = state.excludes.filter(e=>e!==value);
      setState({...state, excludes: newExcludes});
    }

  }

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>

      <div className="row">
        <div className="col-md-2"> <h5 className="greenanyverse">Requests By Time</h5> </div>
        <div className="col-md-2"> <h4>Since</h4> </div>
        <div className="col-md-2">  </div>
        <div className="col-md-2"> <h4 className="greenanyverse">Include:</h4> </div>
        <div className="col-md-2"> <h4 className="greenanyverse">Exclude:</h4> </div>
      </div>
      

      <div className="row mt-3 mb-3">
        <div className="col-md-2">
            <input type="number" className="form-control" placeholder="Micro (1000=1s)" onChange={(e)=>{ setState({...state, search: parseInt(e.target.value)})}} />
        </div>

        <div className="col-md-2">
          <input type="date" className="form-control" value={state.since} onChange={updateSince} />
        </div>

        <div className="col-md-2">
          <input type="date" className="form-control" value={state.to} onChange={updateTo} />
        </div>


        <div className="col-md-2">
          <div className="row">
            <div className="col-md-8 pe-0">
              <input type="text" className="form-control rounded-0 rounded-start" onChange={(e)=>{setState({...state, include_input: e.target.value})}} />
            </div>
            <div className="col-md-2 p-0">
              <input type="button" className="btn btn-success form-control rounded-0 rounded-end" value="+" disabled={(state.include_input.length <= 1)} onClick={(e)=>{include('add')}} ></input>
            </div>
          </div>
        </div>

        <div className="col-md-2">
            <div className="row">
            <div className="col-md-8 pe-0">
              <input type="text" className="form-control rounded-0 rounded-start" onChange={(e)=>{setState({...state, exclude_input: e.target.value})}} />
            </div>
            <div className="col-md-2 p-0">
              <input type="button" className="btn btn-danger form-control rounded-0 rounded-end" value="-" disabled={(state.exclude_input.length <= 1)} onClick={(e)=>{exclude('add')}} ></input>
            </div>
          </div>
        </div>

        <div className="col-md-2">
            <input
              type="button"
              className="btn btn-success"
              onClick={newSearch}
              value="Find logs"
              disabled={!state.search}
              />
        </div>


      </div>

      {(state?.error!==null&&<div className="alert alert-danger">{state.error}</div>)}

      { state.includes.map(e=><button key={e} onClick={()=>include('delete',e)} className="btn btn-success m-1">+ {e}</button>) }
      { state.excludes.map(e=><button key={e} onClick={()=>exclude('delete',e)} className="btn btn-danger m-1">- {e}</button>) }

      <div>
        <div>
          {((state?.status==='loaded' && !waiting)&&<DataTable
              columns={columns}
              data={filterData()}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
          />)}
        </div>

        {waiting && <Loading />}


      </div>

    </div>
  )
};

export default LogRequestByTime;
import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import { JsonEditor as Editor } from 'jsoneditor-react';

const GetUser = () => {

  const [state, setState] = useState({status:"loading"});

  useEffect(async ()=>{
    const usage = await API.get('/v3/api/auth/usage', {});
    const quotas = await API.get('/v3/api/auth/quotas', {});

    setState({...state, status:'loaded', usage: usage, quotas: quotas});
  },[]);


  if(state.status !== "loaded"){ return <div></div>;}

  return(
    <Fragment>
      <h2>Me</h2>
      <br/>
      <div className="row">
        <div className="col-md-6">
          <h4 className="mb-4">Usage</h4>
          <Editor value={state.usage} mode="view"/>
        </div>
        <div className="col-md-6">
          <h4 className="mb-4">Quotas</h4>
          <Editor value={state.quotas} mode="view"/>
        </div>
      </div>
    </Fragment>

  )
}

export default GetUser;
import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from "../../theme/loading";

const Extends = () => {
  const [state, setState] = useState({
    disabled:false,
    status:"loading",
    license:[]
  });

  const nav = useNavigate();
  const {idUser, idLicense} = useParams();

  useEffect(()=>{

    API.get('/v3/api/auth/user/'+idUser, {}).then((resp)=>{

      try {

        if(resp.status !== true){ throw "You don't have permissions or the user don't exit"; }

        let license = resp.data.licenses.find(l=>l.id===idLicense);
        license.expiration = license.expiration.slice(0,10)

        if(license === undefined){ throw "The license don't exist in this user."; }

        setState({status:"loaded", license: license, user: resp.data});
        
      } catch (error) {
        setState({status:"error", message: error});
      }

    });

  },[]);

  const createUser = () =>{

    const date = new Date(state.license.expiration).toISOString();

    API.put('/v3/api/licenses/expiration/'+idLicense+"/"+idUser+"?expire="+date).then( (e)=>{

      if(e.state){
        nav('/app/user/edit/'+idUser);
      }else{
        setState({...state, disabled:false, alert:"danger", message:"An error occurred, maybe the date has an invalid format"});
      }

    } )

  }

  const validateDate = () => {

    const now = +new Date();
    const expiration = +new Date(state.license.expiration);

    if(isNaN(expiration)){
      return <div className="mt-3 ms-2 text-danger">Invalid date.</div>
    }

    if(expiration < now){
      return <div className="mt-3 ms-2 text-danger">You can't put a date before today.</div>
    }

    const diffDays = parseInt( ((expiration - now) / 1000) / 86400 );

    if(expiration >= now){
      return <div className="mt-3 ms-2 text-success">Expire in {diffDays} days.</div>
    }

  }

  const validateDateBool = () => {

    const now = +new Date();
    const expiration = +new Date(state.license.expiration);

    if(expiration >= now){
      return true;
    }

    return false;

  }

  if(state.status === "loading"){ return <Loading />; }
  if(state.status === "error"){ return <div className="alert alert-danger">{state?.message}</div>; }

  return(
    <Fragment>
      <h2>Extend License</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Login:</h4>
          <input type="text" id="user" className="form-control" defaultValue={state.user.login} disabled />
          <br/><br/>
          <h4>License:</h4>
          <input type="text" id="user" className="form-control" defaultValue={state.license.name+" ("+state.license.id+")"}  disabled />
          <br/><br/>
          <h4>Expiration:</h4>
          <input type="date" className="form-control" defaultValue={state.license.expiration} onChange={e=>{setState({...state, license:{...state.license, expiration: e.target.value}})}} />
          <span>{validateDate()}</span>
          <br/><br/>
          <button className="btn btn-success" onClick={createUser} disabled={!validateDateBool()}>Edit expiration</button>
        </div>
      </div>
    </Fragment>
  )
};

export default Extends;
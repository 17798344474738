import { useEffect, useState } from "react";
import API from '../../../config/API';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useParams } from 'react-router-dom';
// https://github.com/vankop/jsoneditor-react

const AddPixel = () => {

  const {id} = useParams();

  const [state, setState] = useState({
    status : "loading",
    user : {},
    button_enable: true,
    // DATA
    pixels: null,
    motivation: undefined,
    notes: undefined
    // Action
  });

  useState(()=>{
    API.get('/v3/api/auth/user/'+id, {}).then((resp)=>{

      if(resp.status){
        setState({...state, status:"loaded", user:{
          login: resp.data.login
        }});
      }else{
        setState({status:"error", message: "You don't have permissions or the user don't exit"});
      }
    });

  },[]);


  const addPixels = () => {

    const obj = {
      user: id,
      pixels: parseInt(state.pixels),
      motivation: document.getElementById("motivationID").value,
      notes: state.notes
    }

    setState({...state, button_enable: false, doAction: {time:obj}});

    API.post('/v3/api/auth/user/addPixels', obj).then((resp)=>{

      if(resp?.status){
        setState((oldState)=>{
          return {...oldState, alert: "success", message: "Pixels have been added successfully", button_enable:true}
        });
      }else{

        setState((oldState)=>{
          return {...oldState, alert: "danger", message: "Error: "+resp?.error, button_enable:true}
        });
        
      }
    });


  }
  
  if(state.status === "error"){ return <div className={"alert alert-danger mt-5"}>{state.message}</div>;}
  if(state.status !== "loaded"){ return <div></div>;}

  return(
    <div>
      <h2>Add Pixel</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <h4>Login</h4>
      <div>{state.user.login}</div>

      <div className="row"> <div className="col-md-6">
        <h4 className="mt-4">Pixels</h4>
        <input type="number" id="pixels" onChange={(e)=>{setState({...state, pixels:e.target.value})}} className="form-control" ></input>
        {
        !!state.pixels &&
        <label className="mt-2">{state.pixels+" pixels = "+(state.pixels/1000000000)+" gigapixel/s"}</label>
        }
        <h4 className="mt-4">Motivation</h4>
        <select className="form-control" id="motivationID">
          <option value={"pay"}>Pay</option>
          <option value={"gift"}>Gift</option>
        </select>
        <h4 className="mt-4">Notes</h4>
        <input type="text" id="notes" onChange={(e)=>{setState({...state, notes:e.target.value})}} className="form-control" placeholder="Optional" ></input>
      </div></div>

      <button className="btn btn-success mt-5" onClick={addPixels} disabled={!(!!state.pixels && state.button_enable)}>Add Pixels</button>


    </div>
  )
};

export default AddPixel;
import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {slugify} from '../../../config/funcaux';
import Loading from "../../theme/loading";

const LicenseEdit = () => {
  const [state, setState] = useState({disabled:false, data:{}, status: 'loading' });
  const {id} = useParams();

  const nav = useNavigate();

  useEffect(()=>{

    API.get('/v3/api/licenses/'+id).then((data)=>{

      if(typeof data === "string"){
        return setState({...state, alert:"danger", message:data, status: "error"});
      }

      if(data?.data?.editable !== true){
        return setState({...state, alert:"danger", message: "You can't edit this license", status: "error"});
      }

      return setState({...state, data:data.data, status: "loaded"})
    });

  }, []);

  const updateLicense = () =>{
    
    API.put('/v3/api/licenses/'+id, state.data).then((data)=>{
      nav('/app/licenses/');
    });

  }

  const validToSend = () => {

    if( 
      state.data.name.length >= 3 &&
      state.data.description.length >= 3
    ){
      return true;
    }else{
      return false;
    }

  }

  const updateData = (json) => {
    setState({...state, data:{...state.data, template: json}});
  }

  if( state.status === "error" ){
    return <div>
       <h2>Edit License</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      </div>;
  }

  if( state.status !== "loaded" ){ return <Loading></Loading>; }

  return(
    <Fragment>
      <h2>Edit License</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Name:</h4>
          <input type="text" id="name" className="form-control" value={state.data.name} onChange={(e)=>{ setState({...state, data:{...state.data, name: slugify(e.target.value)}}) }}/>
          <p className="ms-2 mt-2 fw-lighter">ID: {state.data.id}</p>
          <h4 className="mt-4">Description:</h4>
          <input type="text" id="description" className="form-control" value={state.data.description} onChange={(e)=>{ setState({...state, data:{...state.data, description: e.target.value}}) }}/>
          <h4 className="mt-4">Type:</h4>
          <select className="form-select" id="type" value={state.data.type}  onChange={(e)=>{ setState({...state, data:{...state.data, type: e.target.value}}) }}>
            <option value={"base"}>Base</option>
            <option value={"normal"}>Normal</option>
            <option value={"boost"}>Boost</option>
          </select>

          <h4 className="mt-4">Permissions :</h4>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="checkEditable" checked={state.data.editable} onChange={e=>setState({...state,data:{...state.data, editable:e.target.checked}})} />
            <label className="form-check-label">Editable</label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="checkEditable" checked={state.data.erasable} onChange={e=>setState({...state,data:{...state.data, erasable:e.target.checked}})}   />
            <label className="form-check-label">Erasable</label>
          </div>

        </div>
        <div className="col-md-6">
        <Editor
            value={state.data.template}
            onChange={updateData}
          />
          <br/><br/>
          <button className="btn btn-success" onClick={updateLicense} disabled={!validToSend()}>Create license</button>

        </div>
      </div>
    </Fragment>
  )
};

export default LicenseEdit;
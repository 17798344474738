import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../../config/API';
import Loading from '../../../theme/loading';

const ListDynanamicResources = () => {

  const [state, setState] = useState({ status : "loading", data: [], search: "" })


  useEffect(()=>{
    API.get('/v3/api/utils/dynamicresources', {}).then((data)=>{
        setState({status:"loaded", data:data, search:""});
    });
  },[]);

  const filtersDynanamicResources = () => {

    return state.data.filter((e)=>{

      return ((e.id+e.name).indexOf(state.search) !== -1?true:false);

    });

  }

  const columns = [
    { name: 'ID', selector: (row) => (<Link to={"/app/dynamicresource/edit/"+row.id}>{row.id}</Link>), },
    { name: 'Actions', selector: (row) => (<Fragment><Link to={"/app/dynamicresource/edit/"+row.id}>Edit item</Link></Fragment>), }
  ];

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>
      <h2>Dynamic Resources list</h2>
      
      <div className="row mt-3 mb-3">
        <div className="col-md-9">
          <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
        </div>
        <div className="col-md-3">
          <Link to="/app/dynamicresource/add"><button className="btn btn-success form-control">Add new item</button></Link>
        </div>
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filtersDynanamicResources(state.data)}
        />)}


    </div>
  )
};

export default ListDynanamicResources;
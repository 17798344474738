
if(process.env.REACT_APP_ENV === "PRODUCTION"){
	var APIenv = process.env.REACT_APP_PRODUCTION;
}else if(process.env.REACT_APP_ENV === "DEVELOPMENT"){
	var APIenv = process.env.REACT_APP_DEVELOPMENT;
}else if(process.env.REACT_APP_ENV === "TEST"){
	var APIenv = process.env.REACT_APP_TEST;
}else{
	var APIenv = process.env.REACT_APP_LOCAL;
}

const API = {

	endpoint: APIenv,
	save_token: function(token){
		localStorage.setItem("token", token);
	},

	get_token: function(){
		if (localStorage.getItem("token") == null) {
			return false;
		} else {
			return localStorage.getItem("token");
		}
	},

	remove_token: async function(){
		await API.post('/v3/api/auth/signout');
		localStorage.removeItem("token");
	},

	post: async function(method, obj={}, type="POST"){
		const url = this.endpoint + method;
		const response = await fetch(url, {
			method: type,
			headers: {
				'Content-Type': 'application/json',
				'token': this.get_token()
			},
			redirect: 'follow',
			body: JSON.stringify(obj)
		});
		return response.json()
	},

	get: async function(method, obj={}, raw=false){

		const query = Object.keys(obj)
			.map(k=>encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
			.join('&');

		const url = this.endpoint + method + "?" + query;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'token': this.get_token()
			},
			redirect: 'follow'
		});

		if(raw===false){ return response.json(); }
		if(raw===true){ return response.text(); }
		if(raw==="blob"){ return response.blob(); }

	},

	get_debug: async function(method, obj={}, raw=false){
		console.log("%c WARNING!!! WE'RE USING GET_DEBUG!!!", "background:red; color:white;")

		const query = Object.keys(obj)
			.map(k=>encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
			.join('&');

		const url = process.env.REACT_APP_TEST + method + "?" + query;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/json',
				'token': this.get_token()
			},
			redirect: 'follow'
		});

		if(raw===false){ return response.json(); }
		if(raw===true){ return response.text(); }
		if(raw==="blob"){ return response.blob(); }

	},

	put: function(method, obj ={}){ return this.post(method, obj, "PUT"); },
	delete: function(method, obj ={}){ return this.post(method, obj, "DELETE"); },

	get_direc: async function(urlgoogle, obj={}, raw=false){

		const query = Object.keys(obj)
			.map(k=>encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]))
			.join('&');

		const url = urlgoogle + "?" + query;

		const response = await fetch(url, {
			method: "GET",
			headers: {
				'Content-Type': 'application/octet-stream',
				"processData": false
			},
			redirect: 'follow'
		});

		if(raw===false){ return response.json(); }
		if(raw===true){ return response.text(); }
		if(raw==="blob"){ return response.blob(); }

	},
}

export default API;
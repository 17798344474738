import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import API from './config/API';
import { GlobalDebug } from "./config/globalDebug";
import './style.css';

// Pages
	import App from './pages/app';
	import Login from './pages/login';

// Content
  import ListUsers from './components/users/list';
  import PermissionsUsers from './components/users/permissions';
  import ChangePassword from './components/users/change-password';
  import EditUsers from './components/users/edit';
  import GetUser from './components/users/get';
  import AddUsers from './components/users/add';
  import MeUsers from './components/users/me';
  import UsersTemplate from './components/users/template';
  import TokenListAdmin from './components/users/tokenslist/index.admin';
  import TokenListUsers from './components/users/tokenslist/index.user';
  import AddUsersParams from './components/users/addparams';
  import TestCICD from './components/testCICD';

  import ListThemes from './components/themehtml/list';
  import AddTheme from './components/themehtml/add';
  import EditTheme from './components/themehtml/edit';

  import ConfList from './components/config/configuration/list';
  import ConfAdd from './components/config/configuration/add';
  import ConfEdit from './components/config/configuration/edit';
  import DynamicList from './components/config/dynamicresources/list';
  import DynamicAdd from './components/config/dynamicresources/add';
  import DynamicEdit from './components/config/dynamicresources/edit';

  import ListMachines from './components/machines/list';
  import MetadataMachines from './components/machines/metadata';
  import TopviewMachines from './components/machines/topview';

  import BigQuery from './components/bigquery';

  import Mailing from './components/mailing/list';
  import MailingSendTest from './components/mailing/sendTest';

  import ErrorJobs from './components/errorjobs/list';

  import Pixels from './components/users/pixels';
  import RenderNodes from './components/users/render-nodes';

  import LicensesList from './components/licenses/list';
  import LicensesAdd from './components/licenses/add';
  import LicensesEdit from './components/licenses/edit';
  import LicensesGet from './components/licenses/get';
  import LicensesExtends from './components/licenses/extend';
  import LicensesAddToUsers from './components/licenses/addtouser';

  import LogJob from './components/logs/job';
  import LogMachine from './components/logs/machine';
  import LogRequestBySize from './components/logs/requestBySize';
  import LogRequestByTime from './components/logs/requestByTime';

  import Datasets from './components/users/datasets';

  import InfrastructureNew from './components/infrastructure/new';

const wlhost = window.location.hostname;
if( !(wlhost.startsWith("test-") || wlhost.startsWith("dev-") || wlhost.startsWith("local")) ){
	GlobalDebug(false);
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
		<Routes>
			<Route path="/" element={<Login/>}></Route>
			<Route path="/app" element={<App/>}></Route>
			<Route path="/app/users/list" element={<App content={<ListUsers />}/>}></Route>
			<Route path="/app/users/permissions" element={<App content={<PermissionsUsers />}/>}></Route>
			<Route path="/app/user/change-password/:id" element={<App content={<ChangePassword />}/>}></Route>
			<Route path="/app/user/edit/:id" element={<App content={<EditUsers />}/>}></Route>
			<Route path="/app/user/get/:id" element={<App content={<GetUser />}/>}></Route>
			<Route path="/app/user/add" element={<App content={<AddUsers />}/>}></Route>
			<Route path="/app/user/me" element={<App content={<MeUsers />}/>}></Route>
			<Route path="/app/user/sessions" element={<App content={<TokenListUsers />}/>}></Route>
			<Route path="/app/user/add-param" element={<App content={<AddUsersParams />}/>}></Route>
			<Route path="/app/user/tokens/:id" element={<App content={<TokenListAdmin />}/>}></Route>
			<Route path="/app/users/template" element={<App content={<UsersTemplate />}/>}></Route>
			<Route path="/app/user/datasets/:id" element={<App content={<Datasets />}/>}></Route>

			<Route path="/app/testCICD" element={<App content={<TestCICD />}/>}></Route>

			<Route path="/app/themes" element={<App content={<ListThemes />}/>}></Route>
			<Route path="/app/themes/add" element={<App content={<AddTheme />}/>}></Route>
			<Route path="/app/themes/edit/:id" element={<App content={<EditTheme />}/>}></Route>

			<Route path="/app/configuration" element={<App content={<ConfList />}/>}></Route>
			<Route path="/app/configuration/add" element={<App content={<ConfAdd />}/>}></Route>
			<Route path="/app/configuration/edit/:id" element={<App content={<ConfEdit />}/>}></Route>
			<Route path="/app/dynamicresource" element={<App content={<DynamicList />}/>}></Route>
			<Route path="/app/dynamicresource/add" element={<App content={<DynamicAdd />}/>}></Route>
			<Route path="/app/dynamicresource/edit/:id" element={<App content={<DynamicEdit />}/>}></Route>

			<Route path="/app/machines/list" element={<App content={<ListMachines />}/>}></Route>
			<Route path="/app/machines/metadata" element={<App content={<MetadataMachines />}/>}></Route>
			<Route path="/app/machines/topview" element={<App content={<TopviewMachines />}/>}></Route>
			<Route path="/app/infrastructure/new" element={<App content={<InfrastructureNew />}/>}></Route>
			
			<Route path="/app/bigquery" element={<App content={<BigQuery />}/>}></Route>

			<Route path="/app/mailing" element={<App content={<Mailing />}/>}></Route>
			<Route path="/app/mailing/sendtest/:id" element={<App content={<MailingSendTest />}/>}></Route>

			<Route path="/app/errorjobs" element={<App content={<ErrorJobs />}/>}></Route>

			<Route path="/app/user/pixels/:id" element={<App content={<Pixels />}/>}></Route>
			<Route path="/app/user/rendernodes/:id" element={<App content={<RenderNodes />}/>}></Route>

			<Route path="/app/licenses" element={<App content={<LicensesList />}/>}></Route>
			<Route path="/app/licenses/add" element={<App content={<LicensesAdd />}/>}></Route>
			<Route path="/app/licenses/edit/:id" element={<App content={<LicensesEdit />}/>}></Route>
			<Route path="/app/licenses/get/:id" element={<App content={<LicensesGet />}/>}></Route>
			<Route path="/app/licenses/extend/:idUser/:idLicense" element={<App content={<LicensesExtends />}/>}></Route>
			<Route path="/app/licenses/addToUser/:idUser" element={<App content={<LicensesAddToUsers />}/>}></Route>
			
			<Route path="/app/logs/job" element={<App content={<LogJob />}/>}></Route>
			<Route path="/app/logs/job/:id/:date" element={<App content={<LogJob />}/>}></Route>
			<Route path="/app/logs/machine" element={<App content={<LogMachine />}/>}></Route>
			<Route path="/app/logs/requestBySize" element={<App content={<LogRequestBySize />}/>}></Route>
			<Route path="/app/logs/requestByTime" element={<App content={<LogRequestByTime />}/>}></Route>

		</Routes>
	</BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../../components/theme/loading';
import JSON from '../../../helpers/jsonviewer';

const ListMachines = () => {

  const [state, setState] = useState({
    status : "loading",
    search: "",
    machines: [],
    selected: null
  })

  useEffect(()=>{
    API.get('/v3/api/admin/getTopviewMachines', {}).then((data)=>{

      if(data){
        setState({...state, status:"loaded", machines: data, search:""});
      }else{
        setState({...state, status:"error", message: "You don't have permissions"});
      }

    });
  },[]);


  const filterUsers = () => {


    return state.machines.filter( m => {

      let pass = true;

      if( state.search !== "" ){
        if( (m.id+(m?.name||"")+(m?.status||"")).toLocaleLowerCase().includes(state.search.toLocaleLowerCase()) === false ){
          pass = false;
        }
      }

      return pass;

    })

  }

  const viewDetails = (id) => {
    setState({...state, selected: id});
  }

  const getMachineByID = (id) => {
    const machines = state.machines.find(m => m.id === id);

    return machines;
  }


  /*
    THEME
  */
  const columns = [
    { name: 'ID', selector: row => (<Link to={"/app/machine/edit/"+row.id}>{row.id}</Link>), },
    { name: 'name', selector: (row) =>  row.name, sortable: true },
    { name: 'status', selector: (row) =>  row.status, sortable: true },
    { name: 'created', selector: (row) =>  row.creationTimestamp, sortable: true },
    { name: '', selector: (row) => <span className="greenanyverse pointer" onClick={()=>viewDetails(row.id)}>Details</span> }
  ];

  if(state.status === "loading"){ return <Loading></Loading> }

  return(
    <div>
      <h2>Topview machines <span className="fs-6">{filterUsers(state.machines).length+" / "+state.machines.length}</span> </h2>

      <h5 className="mt-4">Search</h5>
      <div className="p-3 pt-0">
        <input placeholder="Search (id, project, job...)" className="form-control" onChange={e=>{setState({...state, search: e.target.value})}}></input>
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filterUsers(state.machines)}
        />)}

        <div className="mt-3">
          {state.selected && 
            <JSON value={getMachineByID(state.selected)}></JSON>
          }
        </div>

    </div>
  )
};

export default ListMachines;
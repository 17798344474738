import { Fragment, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';

const AddParams = () => {
  const [state, setState] = useState({disabled:false});
  const nav = useNavigate();

  const createParam = () =>{
    setState({...state, disabled:true});

    const jsontext = document.getElementById("jsontext").value;

    try {
      const obj = JSON.parse(jsontext);

      API.post('/v3/api/auth/user/params', obj).then( (e)=>{

        if(e.status){
          nav('/app/users');
          //setState({...state, disabled:false, alert:"success", message:"The user was created correcy"});
        }else{
          setState({...state, disabled:false, alert:"danger", message:"An error occurred, maybe the text is an invalid JSON."});
        }

      } )

    } catch (error) {
      setState({...state, disabled:false, alert:"danger", message:"Please, use a valid JSON"});
    }

  }

  return(
    <Fragment>
      <h2>Add param</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>JSON:</h4>
          <input type="text" id="jsontext" className="form-control" placeholder='{ "canSelectCustomSL": false }' />
          <br/><br/>
          <button className="btn btn-success" onClick={createParam} disabled={state.disabled}>Add param</button>
        </div>
      </div>
    </Fragment>
  )
};

export default AddParams;
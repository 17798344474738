import MenuAdmin from '../menu/admin';

const Body = (props) => {


  return(
    <div className="row">
      <div className="col-md-2 border-av-right">
      <MenuAdmin />
      </div>
      <div className="col-md-10 mt-3">
      {props.content}
      </div>
    </div>
  )
};

export default Body;
import './style.css';

const Loading = (props) => {

    const text = (props?.text ? props.text : "Loading...")

    return <div>
            
            <div className="lds-ellipsis">
                <h5 className='m-0'>{text}</h5>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>;

};

export default Loading;
import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import Loading from "../../theme/loading";

const LicensesAddToUsers = () => {
  const aMonth = 86400*30*1000;
  const [state, setState] = useState({
    disabled:false,
    status:"loading",
    license:[],
    expiration: new Date((+ new Date())+aMonth).toISOString().slice(0,10)
  });

  const nav = useNavigate();
  const {idUser} = useParams();

  useEffect(async ()=>{

    const user = await API.get('/v3/api/auth/user/'+idUser, {});
    const licenses = await API.get('/v3/api/licenses', {});
    if(user.status){
      return setState({...state, status:"loaded", user: user.data, licenses: licenses});
    }

    return setState({...state, status: 'error', message: 'Invalid user.'})

  },[]);

  const validateDate = () => {

    const now = +new Date();
    const expiration = +new Date(state.expiration);
    console.log(expiration);

    if(isNaN(expiration)){
      return <div className="mt-3 ms-2 text-danger">Invalid date.</div>
    }

    if(expiration < now){
      return <div className="mt-3 ms-2 text-danger">You can't put a date before today.</div>
    }

    const diffDays = parseInt( ((expiration - now) / 1000) / 86400 );

    if(expiration >= now){
      return <div className="mt-3 ms-2 text-success">Expire in {diffDays+1} days.</div>
    }

  }

  const validateDateBool = () => {

    const now = +new Date();
    const expiration = +new Date(state.license.expiration);

    if(expiration >= now){
      return true;
    }

    return false;

  }

  const addLicense = () =>{

    const date = new Date(state.expiration).toISOString();
    const license = document.getElementById("license").value;

    API.post('/v3/api/licenses/addToUser/'+license+"/"+idUser+"?expire="+date).then( (e)=>{

      if(e.state){
        nav('/app/user/edit/'+idUser);
      }else{
        setState({...state, disabled:false, alert:"danger", message:"An error occurred, maybe the date has an invalid format"});
      }

    } )

  }

  if(state.status === "loading"){ return <Loading />; }
  if(state.status === "error"){ return <div className="alert alert-danger">{state?.message}</div>; }

  return(
    <Fragment>
      <h2>Add User</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Login:</h4>
          <input type="text" id="user" className="form-control" defaultValue={state.user.login} disabled />
          <br/><br/>
          <h4>License:</h4>
          <select className="form-select" id="license">
            {
              state.licenses.map(l=><option value={l.id} key={l.id}>{l.name}</option>)
            }
          </select>
          <br/><br/>
          <h4>Expiration:</h4>
          <input type="date" className="form-control" defaultValue={state.expiration} onChange={e=>{setState({...state, expiration: e.target.value})}} />
          <span>{validateDate()}</span>
          <br/><br/>
          <button className="btn btn-success" onClick={addLicense} disabled={!validateDate()}>Add license</button>
        </div>
      </div>
    </Fragment>
  )
};

export default LicensesAddToUsers;
import { useEffect, useState } from "react";
import API from '../../../config/API';
import Editor from '../../../helpers/jsonviewer';
import { useParams } from 'react-router-dom';
import Loading from '../../theme/loading';
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
// https://github.com/vankop/jsoneditor-react

const EditUsers = () => {

  const {id} = useParams();

  const [state, setState] = useState({
    status : "loading",
    user : {},
    loadAgain: +new Date()
  });

  useEffect(()=>{
    API.get('/v3/api/auth/user/'+id, {}).then((resp)=>{

      if(resp.status){

        setState({...state,
          status:"loaded", user:{
          data: resp.data.data,
          usage: resp.data.usage,
          login: resp.data.login,
          quota: resp.data.quota,
          secure: resp?.data?.secure || false,
          licenses: resp.data.licenses || [],
        }});

      }else{
        setState({status:"error", message: "You don't have permissions or the user don't exit"});
      }
    });

  },[state.loadAgain]);

  const updateData = (json) => { setState({...state, user:{...state.user, data: json}}); }

  const updateQuota = (json) => { setState({...state, user:{...state.user, quota: json}}); }
  
  const updateLogin = (e) => { setState({...state, user:{...state.user, login: e.target.value}}); }

  const saveUser = () => {
    
    API.put('/v3/api/auth/user/'+id, state.user).then((resp)=>{

      if(resp.status){
        setState({...state, alert:'success', message: 'The user was successfully updated.'});
      }else{
        setState({...state, alert:'danger', message: 'We could not update the user.'});
      }

    });

  }

  const recalculateLicense = async () => {
    API.get("/v3/api/auth/recalculateLicenses/"+id).then((res)=>{
      if(res.status){
        setState({...state, alert:'success', message: "The user's licenses were successfully recalculated.", loadAgain: +new Date()});

      }else{
        setState({...state, alert:'danger', message: 'An error occurred when trying to recalculate the licenses for this user.'});
      }

    });
  }

  const add2FA = () =>{
    API.put("/v3/api/auth/2FA/enabled", {user: id}).then((res)=>{
      if(res.status){
        setState({...state, alert:'success', message: "The 2FA security has been activated for the specified user", loadAgain: +new Date()});
      }else{
        setState({...state, alert:'danger', message: 'An error occurred when trying to activate 2FA.'});
      }

    });

  }

  
  const columnsLicense = [
    { name: 'Name', selector: (row) => (row.name), },
    { name: 'Activation', selector: (row) => row.activation.slice(0,10), },
    { name: 'Expiration', selector: (row) => row.expiration.slice(0,10), },
    { name: 'Extend', selector: (row) => (<Link to={"/app/licenses/extend/"+id+"/"+row.id}>Extend</Link>), }
  ];

 
  if(state.status === "loading"){ return <Loading/> }
  if(state.status === "error"){ return <div className={"alert alert-danger mt-5"}>{state.message}</div>;}
  if(state.status !== "loaded"){ return <div></div>;}

  return(
    <div>
      <h2>Edit user</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <h4>Login</h4>
      <div className="row">
        <div className="col-md-6">
          <input type="text" defaultValue={state.user.login} onChange={updateLogin} className="form-control col-md-6" />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-md-6">
          <h4>Data</h4>
          <Editor value={state.user.data} onChange={updateData} />
        </div>
        <div className="col-md-6">

          <h4>Usage</h4>
          <Editor value={state.user.usage} mode="view"/>

          <h4 className="mt-5">Quota</h4>
          <Editor value={state.user.quota} onChange={updateQuota}/>

          <h4 className="mt-5">Licenses</h4>
          <DataTable
            columns={columnsLicense}
            data={state.user.licenses}
          />

          <div className="text-end mt-3">
            <Link to={"/app/licenses/addToUser/"+id}>
              <input type="button" className="btn btn-success" value="Add license" />
            </Link>
            <br/>
            <input type="button" className="btn btn-success mt-5" value="Recalcule licenses" onClick={recalculateLicense} />
          </div>


          <h4 className="mt-5">Actions:</h4>
          <div className="text-end">
            { !state?.user?.secure && <button className="btn btn-success" onClick={add2FA}>Añadir 2FA</button> }
          </div>

        </div>
      </div>

      <button className="btn btn-success mt-5" onClick={saveUser}>Update user</button>


    </div>
  )
};

export default EditUsers;
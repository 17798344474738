import { Fragment, useState } from "react";
import API from '../../../../config/API';
import {useNavigate} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {slugify} from '../../../../config/funcaux';

const AddConfig = () => {
  const [state, setState] = useState({disabled:false, data: {name:"example"}, idconfig:""});
  const nav = useNavigate();

  const createConfig = () =>{

    if(state.idconfig === ""){ return alert("ID is required"); }
    
    API.post('/v3/api/utils/configurations/'+state.idconfig, state.data).then((data)=>{
      nav('/app/configuration/');
    });

  }

  const updateData = (json) => {
    setState({...state, data:json});
  }

  return(
    <Fragment>
      <h2>Add Config</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>ID:</h4>
          <input type="text" id="IDconfig" className="form-control" onChange={(e)=>{ setState({...state, idconfig: slugify(e.target.value)}) }}/>
          <p className="ms-2 mt-2 fw-lighter">{slugify(state.idconfig)}</p>
          <br/><br/>
          <Editor
            value={state.data}
            onChange={updateData}
          />
          <br/><br/>
          <button className="btn btn-success" onClick={createConfig} disabled={state.disabled}>Create config</button>
        </div>
      </div>
    </Fragment>
  )
};

export default AddConfig;
import { Fragment, useEffect, useState } from "react";
import {useNavigate} from 'react-router-dom';
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import {slugify} from '../../../config/funcaux';
import CodeEditor from '@uiw/react-textarea-code-editor';

const AddTheme = () => {
  global.Buffer = global.Buffer || require('buffer').Buffer

  const nav = useNavigate();
  const [state, setState] = useState({ status : "loading", theme: {}, search: "", name: "" })

  const [code, setCode] = useState( `<!-- Insert here your code -->
<p>Hello world!</p>` );

  const saveTheme = () => {
    const codeb64 = Buffer.from(code).toString('base64');
    const obj = {content: codeb64, name: state.name, type: "html"};

    API.post('/v3/api/themes', obj).then((data)=>{
      console.log(data);
      nav('/app/themes/');
    });
  }

  return(
    <div>
      <h2>Create Theme</h2>
      
      <p className="mt-3">Name:</p>
      <input type="text" className="form-control" onChange={(e)=>{setState({...state, name:e.target.value})}}></input>
      <p className="mb-4 p-2 fst-italic">{slugify(state.name)}</p>

      <p className="mt-3">Content:</p>
      <CodeEditor
      value={code}
      language="html"
      placeholder="Please enter HTML code."
      onChange={(evn) => setCode(evn.target.value)}
      padding={15}
      style={{
        fontSize: 14,
        backgroundColor: "#fff",
        fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
      }}
      />

      <br/>

      <button className="btn btn-success" onClick={saveTheme}>Create theme</button>

    </div>
  )

};

export default AddTheme;
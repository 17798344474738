import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';

import './style.css';

const ErrorJobs = () => {

  const [state, setState] = useState({
    status: "loading",
    renders: [],
    search: "",
    statusWithErrors: 0,
    dateFilter: (new Date(((+new Date()) - 86400000)).toISOString()),
    file: undefined
    });


  useEffect(()=>{

    API.post('/v3/api/jobs/errors', {from:state.dateFilter}).then((data)=>{


      setState({...state, status:"loaded", statusWithErrors: data.length, renders:data});

    });
  },[]);

  const filterData = () => {

    return state.renders.filter((e)=>{

      return ((
          e._id +
          e.dataset +
          e.batch +
          e.user
          ).indexOf(state.search) !== -1?true:false);

    });

  }

  const newSearch = (e) => {

   const date = new Date(e.target.value)
   if(isNaN( +date )){ return; } 
   const dateISO = date.toISOString();

   API.post('/v3/api/jobs/errors', {from:dateISO}).then((data)=>{
      setState({...state, statusWithErrors: data.length, renders:data, dateFilter: dateISO});
    });

  }

  const loadError = (dataset, batch, id, user) => {
    API.get(`/v3/api/jobs/${id}/${user}/error`,{},true).then((data)=>{
      setState({...state, file: data})
    })

  }
  
  const columns = [
    { name: 'ID', selector: (row) =>  row._id, sortable: true },
    { name: 'Dataset', selector: (row) => row.dataset, sortable: true },
    { name: 'Batch', selector: (row) => row.batch, sortable: true },
    { name: 'User', selector: (row) => row.user, sortable: true },
    /*{ name: 'Progress', selector: (row) => row.progress, sortable: true },*/
    { name: 'Created', selector: (row) => row.creation.substring(0,16).replace("T"," "), sortable: true },
    { name: 'Error', selector: (row) => <span className="greenanyverseLink" onClick={()=>{loadError(row.dataset, row.batch, row._id, row.user)}}>Error</span> },
  ];

  if(state.status === "loading"){ return <Loading/> }
  console.log(state);


  return(
    <div className="errorJobComp">
      <h2>Renders Error <span className="h6">{state.renders.length} renders error since {state.dateFilter.substring(0,10)}</span></h2>

      <div className="row mt-3 mb-3">
        <div className="col-md-10">
            <input type="text" className="form-control" placeholder="Search..." onChange={(e)=>{ setState({...state, search: e.target.value})}} />
        </div>
        <div className="col-md-2">
            <input type="date" className="form-control" id="textdatafilter" onChange={newSearch} value={state.dateFilter.substring(0,10)} />
        </div>
      </div>

      {/*(state?.status==='error'&&<div className="alert alert-danger">{state.message}</div>)*/}

      <div>
        <div>
          {(state?.status==='loaded'&&<DataTable
              columns={columns}
              data={filterData(state.renders)}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
          />)}
        </div>
        <div className="mt-5">
          <div className="codetext">
            { state.file && <div>
              <h2>File error:</h2>
              { state.file }
              </div> }
          </div>
        </div>
      </div>



    </div>
  )
};

export default ErrorJobs;
import './styles.css';
import logo from '../../resources/img/logo.png';
import {useState, useEffect} from 'react';
import API from '../../config/API';
import {useNavigate} from 'react-router-dom';
import Loading from '../../components/theme/loading';

const Login = (props) =>{

  const [state, setState] = useState({status: "loading", show2FA: false});
  const navigate = useNavigate()

  useEffect(()=>{

    // Check if has token
      if(!!API.get_token()){

      // Check if the token is valid
        API.get('/v3/api/auth/me',{}).then((data)=>{

          if(typeof data !== "string"){
            navigate('/app');
          }else{
            setState({status:"loaded"});
          }
        });
      }else{
        setState({status:"loaded"});
      }

  },[]);

  const signin = (e) => {
    e.preventDefault();

    const email = document.getElementById("email").value;
    const password = document.getElementById("password").value;

    if(!email || !password){
      setState({message:"Please, enter email and password.", status:"danger"});
      return;
    }

    API.post('/v3/api/auth/signin/2FA',{ email: email, password: password }).then((data)=>{

      if(typeof data === 'object'){
        setState({status:true, show2FA: true});
      }else{
        setState({message:"Invalid email/password or 2FA is disabled", status:"danger"});
      }
    });

  }

  const signinWithCode = (e) => {

    const email = document.getElementById("email").value;
    const code = document.getElementById("code").value;

    if(!email || !code){
      setState({...state, message:"Please, enter email and code.", status:"danger"});
      return;
    }

    API.post('/v3/api/auth/signin/2FA/check',{ email: email, secretkey: code }).then((data)=>{
      if(typeof data === 'object'){
        API.save_token(data.token);
        navigate('/app');  
      }else{
        setState({...state, message:"Invalid email/secretKey.", status:"danger"});
      }
    });

  }

  const backToLogin = () => {
    setState({status:"loaded", show2FA: false});
  }

	return (
		<div className="container mt-5 comLogin">
			<div className="row justify-content-center">
				<div className={"col-md-5 p-5 rounded "+(state.status === "loading"?"loading":"")}>
					<img src={logo} className='w-100'></img>
					<h4 className='mt-2 text-center'>Sign in</h4>

					{(state.status !== false ?
						<div className={"alert alert-"+state.status+" mt-3"}>{state.message}</div>
            :
            <div className='mt-5'></div>
					)}

          {
            state.status === "loading" ?
              <div><Loading></Loading></div>
            :
            <form onSubmit={signin}>
              <input type="email" id="email" name="email" className='form-control' placeholder='Login'></input>
              <br></br>
              { (!state.show2FA &&
                <input type="password" id="password" name="password" className='form-control' placeholder='Password'></input>
              ) }
              { (state.show2FA &&
                <input type="text" id="code" name="code" className='form-control' placeholder='Enter the secret key'></input>
              ) }

              <br></br>
              <input type="submit" className='btn btn-secondary form-control' value="Sign in" onClick={(!state.show2FA?signin:signinWithCode)}></input>
              {(state.show2FA &&
              <div>
                <br /><br />
                <p className='text-end nextlimit greenanyverse pointer' onClick={backToLogin}>← Back to login </p>
              </div>)}
            </form>
          }

				</div>
			</div>
		</div>
	);
}

export default Login;
import { Fragment, useEffect, useState } from "react";
import DataTable, { ExpanderComponentProps } from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';
import Editor from '../../../helpers/jsonviewer';

const LogMachine = () => {

  const [waiting, setWaiting] = useState( false );

  const [state, setState] = useState({
      status: "loaded",
      search: "",
      data: {machine:{}, logs:[]},
      error: null,
      filter: {
        type: 'all',
        severity: 'all'
      },
      since: (new Date( (+new Date())-86400000 ).toISOString().slice(0,10)), // yesterday
      to: (new Date().toISOString().slice(0,10))
    });

  const ExpandedComponent = ({ data }) => <pre>{JSON.stringify(data.raw, null, 2)}</pre>;

  const filterData = () => {

    return state.data.logs.filter(e=>{
      
      if( state.filter.type !== 'all' && !e.logName.includes(state.filter.type) ){
        return false;
      }

      if( state.filter.severity !== 'all' && e.severity.toLowerCase() !== state.filter.severity ){
        return false;
      }

      return true;

    }).map(e=>{

      let type;
        if(e.logName.includes('stdout')){ type="output"; }
        else if(e.logName.includes('request')){ type='request';}
        else if(e.logName.includes('stderr')){ type='error';}
        else{type='other';}

      let payload;
      if(e.logName.includes('stdout') || e.logName.includes('stderr')){ payload=e?.textPayload; }
      else if(e.logName.includes('request')){ payload=e?.httpRequest?.requestMethod+" "+e?.httpRequest?.requestUrl; }
      else{payload='';}

      return {
        hhmm: new Date(e.timestamp.seconds*1000).toISOString().substring(11,19),
        type,
        alert: e.severity,
        payload,
        raw: e
      }

    });

  }

  const newSearch = () => {

    if(
      Number.isNaN(+new Date(state.since)) ||
      Number.isNaN(+new Date(state.to)) ){
      return;
    }

    setWaiting(true);


    API.post('/v3/api/machines/log/'+state.search, {
      "since": (new Date(state.since+"T00:00:00Z").toISOString()),
      "to": (new Date(state.to+"T23:59:59Z").toISOString())
    }).then((data)=>{

      if( data?.machine ){
        setState({...state, data, error: null});
      }else{
        console.log(data);
        setState({...state, error: data});
      }

      setWaiting(false);



    });
  
  }

  const updateSince = (e) => setState({...state, since: e.target.value});

  const updateTo = (e) => setState({...state, to: e.target.value});

  const columns = [
    { name: 'HH:MM', selector: (row) =>  row.hhmm, sortable: true, grow: 0 },
    { name: 'Type', selector: (row) => row.type, sortable: true, grow: 0 },
    { name: 'Alert', selector: (row) => row.alert, sortable: true, grow: 0 },
    { name: 'Payload', selector: (row) => row.payload, sortable: true }
  ];

  const filter = (e) => {

    const newFilter = {...state.filter};

    newFilter[e.target.id] = e.target.value;

    setState({...state, filter: newFilter});

  }

  console.log(state);

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>

      <div className="row">
        <div className="col-md-2"> <h3 className="greenanyverse">Machine logs</h3> </div>
        <div className="col-md-2"> <h4>Since</h4> </div>
        <div className="col-md-2">  </div>
        <div className="col-md-2">  </div>
        <div className="col-md-4"> {state.data.logs.length>0 && <h2>Filters:</h2>} </div>
      </div>
      

      <div className="row mt-3 mb-3">
        <div className="col-md-2">
            <input type="text" className="form-control" placeholder="MachineID" onChange={(e)=>{ setState({...state, search: e.target.value})}} />
        </div>
        <div className="col-md-2">
          <input type="date" className="form-control" value={state.since} onChange={updateSince} />
        </div>
        <div className="col-md-2">
          <input type="date" className="form-control" value={state.to} onChange={updateTo} />
        </div>
        <div className="col-md-2">
            <input
              type="button"
              className="btn btn-success"
              onClick={newSearch}
              value="Find logs"
              disabled={!state.search}
              />
        </div>

        {
          state.data.logs.length>0 && 
          <>
              <div className="col-md-2">
                <select id='type' className="form-control form-select" onChange={filter}>
                  <option value="all">All types</option>
                  <option value="stdout">Output</option>
                  <option value="request">Request</option>
                  <option value="stderr">Error</option>
                </select>
              </div>
              <div className="col-md-2">
                <select id='severity' className="form-control form-select" onChange={filter}>
                  <option value="all">All severities</option>
                  <option value="default">Default</option>
                  <option value="info">Info</option>
                  <option value="notice">Notice</option>
                  <option value="error">Error</option>
                  <option value="warning">Warning</option>
                </select>
              </div>
          </>
        }


      </div>



      {(state?.error!==null&&<div className="alert alert-danger">{state.error}</div>)}

      <div>
        {(waiting === true) && <Loading />}
      </div>

      <div>
        <div>
          {((state?.status==='loaded' && waiting === false)&&<DataTable
              columns={columns}
              data={filterData()}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
          />)}
        </div>

      </div>

      {
        Object.keys(state.data.machine).length>0 && 
        <>
          <h2>Mongo log</h2>
          {<Editor value={state.data.machine} mode="view"/>}
        </>
      }

    </div>
  )
};

export default LogMachine;
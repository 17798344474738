import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { JsonEditor as Editor } from 'jsoneditor-react';
import {Link, useParams} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';

const TokensList = () => {

  const {id} = useParams();

  const [state, setState] = useState({
    status : "loading",
    tokens: [],
    moreinfo: false
  })

  useEffect(()=>{
    loadTokens();
  },[]);

  const loadTokens = () => {
    API.get('/v3/api/auth/tokens/'+id+'/list', {}).then((data)=>{
      if(data.status){
        setState({...state, status:"loaded", tokens: data.data, search:""});
      }else{
        setState({...state, status:"error", message: "Error "+data?.error});
      }
    });
  }

  const moreInfo = (e) => {

    const info = state.tokens.find(row=>row.token===e.target.id);
    const test = <Editor key={e.target.id} value={info} mode="view"/>;

    setState({...state, moreinfo: test });
  }

  const removeToken = (e) => {

    const token = e.target.id;
    let confirm = window.confirm("Are you sure?");


    if(confirm){

      API.delete('/v3/api/auth/tokens/', {removetoken:token}).then((data)=>{
        if(data.status){
          const tokens = state.tokens.filter(row=>row.token!=token);
          setState({...state, status:"loaded", tokens: tokens});
        }else{
          setState({...state, status:"error", message: "You don't have permissions"});
        }
      });
    }

  }

  const removeAll = async (e) => {

    let confirm = window.confirm("Are you sure?");

    if(confirm){

      state.tokens.forEach(async(e) => {
        await API.delete('/v3/api/auth/tokens/', {removetoken: e.token});
      });

      loadTokens();

    }

  }

  const columns = [
    { name: 'Token', maxWidth:"500px", selector: (row) => row.token, },
    { name: 'Created', selector: row => new Date(row.created).toISOString(), },
    { name: 'IP', selector: row => row?.data?.ip },
    { name: 'Google Maps', selector: row => <a href={"https://www.google.com/maps/place/"+row?.data?.loc} target="_blank">{row?.data?.loc}</a> },
    { name: 'More info', selector: row => <Link to="#" onClick={moreInfo} id={row.token}>More info</Link> },
    { name: 'Remove', selector: (row) => (<Fragment><a href="#" id={row.token} onClick={removeToken}>Remove token</a></Fragment>), }
  ];

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>
      <h2>Tokens ({(state?.tokens?.length?state?.tokens?.length:0)})</h2>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {
        (state.moreinfo &&
        <div className="m-5">{state.moreinfo}</div>)
      }


      {(state.status==='loaded'&&<DataTable
          columns={columns}
          data={state.tokens}
      />)}

      <div className="text-end">
        <button className="btn btn-danger mt-3 mb-3" onClick={removeAll}>Delete all tokens</button>
      </div>
    </div>
  )
};

export default TokensList;
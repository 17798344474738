import { useEffect, useState } from "react";
import API from '../../../config/API';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useParams } from 'react-router-dom';
// https://github.com/vankop/jsoneditor-react

const ChangePassword = () => {

  const {id} = useParams();

  const [state, setState] = useState({
    status : "loading",
    user : {},
    password: ""
  });

  useState(()=>{
    API.get('/v3/api/auth/user/'+id, {}).then((resp)=>{

      if(resp.status){
        setState({...state, status:"loaded", user:{
          login: resp.data.login
        }});
      }else{
        setState({status:"error", message: "You don't have permissions or the user don't exit"});
      }
    });

  },[]);

  const yourJson = {persona:"carla", email:"luis@peris.com"};

  const savePassword = () => {

    API.post('/v3/api/auth/updatePassword', {
      email: state.user.login,
      newpassword: state.password
    }).then((data)=>{
      if(data?.token){
        setState({...state, alert:"success", message: "The password was updated correctly"});
      }else{
        setState({...state, alert:"danger", message: "Something went wrong"});
      }
    });

  }

  
  if(state.status === "error"){ return <div className={"alert alert-danger mt-5"}>{state.message}</div>;}
  if(state.status !== "loaded"){ return <div></div>;}

  return(
    <div>
      <h2>Change Password</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <h4>Login</h4>
      <div>{state.user.login}</div>

      <h4 className="mt-4">New password</h4>
      <div className="row"> <div className="col-md-6">
        <input type="password" id="password" onChange={(e)=>{setState({...state, password:e.target.value})}} className="form-control" placeholder="**********"></input>
      </div></div>

      <button className="btn btn-success mt-5" onClick={savePassword} disabled={(state?.password?.length<=3?true:false)}>Change Password</button>


    </div>
  )
};

export default ChangePassword;
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../../components/theme/loading';

const ListMachines = () => {

  const [state, setState] = useState({
    status : "loading",
    users: [],
    search: "",
    machines: [],
    filter: {
      status: { values:[], selected: null },
      usage: { values:[], selected: null },
      user: { values:[], selected: null },
      zone: { values:[], selected: null },
      dataset: { values:[], selected: null },
      env: { values:[], selected: null }
    }
  })

  useEffect(()=>{
    API.get('/v3/api/admin/getAllAnyverseMachines', {}).then((data)=>{

      if(data){

        const [machines, filter] = prepareMachines(data);

        setState({...state, status:"loaded", machines, filter, search:""});

      }else{
        setState({...state, status:"error", message: "You don't have permissions"});
      }

    });
  },[]);

  const prepareMachines = (machines) => {

    const filters = {
      status: { values:[], selected: null },
      usage: { values:[], selected: null },
      user: { values:[], selected: null },
      zone: { values:[], selected: null },
      dataset: { values:[], selected: null },
      env: { values:[], selected: null }
    }

    const newMachines = [];
    const idsMachines = [];

    machines.forEach(e => {

      if( !filters.dataset.values.includes(e.dataset) && e.dataset !== undefined ){ filters.dataset.values.push(e.dataset) }
      if( !filters.env.values.includes(e.env) && e.env !== undefined ){ filters.env.values.push(e.env) }
      if( !filters.status.values.includes(e.status) && e.status !== undefined ){ filters.status.values.push(e.status) }
      if( !filters.usage.values.includes(e.usage) && e.usage !== undefined ){ filters.usage.values.push(e.usage) }
      if( !filters.zone.values.includes(e.zone) && e.zone !== undefined ){ filters.zone.values.push(e.zone) }
      if( !filters.user.values.includes(e.user) && e.user !== undefined ){ filters.user.values.push(e.user) }

      if( !idsMachines.includes(e.id) ){
        idsMachines.push(e.id);
        newMachines.push(e);
      }

    });

    return [newMachines, filters];
    
  }

  const addElement = (ele) => {

    state.filter[ele].selected = document.getElementById("select-"+ele).value;

    setState({...state});
  }

  const deleteOption = (key) => {
    state.filter[key].selected = null;
    setState({...state});
  }

  const filterUsers = () => {

    const keysFilter = Object.keys(state.filter);

    return state.machines.filter( m => {

      let pass = true;

      keysFilter.forEach(k => {
        if(state.filter[k].selected !== null){
          if( m[k] !== state.filter[k].selected ){
            pass = false;
          }
        }
      })

      if( state.search !== "" ){
        if( (m.id+(m?.project||"")+(m?.job||"")).includes(state.search) === false ){
          pass = false;
        }
      }

      return pass;

    })

  }

  const capitalizeFirstLetter = ([ first, ...rest ], locale = navigator.language) =>
  first === undefined ? '' : first.toLocaleUpperCase(locale) + rest.join('')

  /*
    THEME
  */
  const columns = [
    { name: 'ID', selector: row => (<Link to={"/app/machine/edit/"+row.id}>{row.id}</Link>), },
    { name: 'user', selector: (row) =>  row.user, sortable: true },
    { name: 'status', selector: (row) =>  row.status, sortable: true },
    { name: 'usage', selector: (row) =>  row.usage, sortable: true },
    { name: 'zone', selector: (row) =>  row.zone, sortable: true }
  ];


  if(state.status === "loading"){ return <Loading></Loading> }

  return(
    <div>
      <h2>List machines <span className="fs-6">{filterUsers(state.machines).length+" / "+state.machines.length}</span> </h2>

      <h5 className="mt-4">Search</h5>
      <div className="p-3 pt-0">
        <input placeholder="Search (id, project, job...)" className="form-control" onChange={e=>{setState({...state, search: e.target.value})}}></input>
      </div>


      <div className="row mt-4 mb-4">
        {
          // Show columns
          Object.keys(state.filter).map( k => {
            return <div className="col-md-2">
              <h5>{capitalizeFirstLetter(k)}</h5>
              <select className="form-control" id={"select-"+k}>
                {state.filter[k].values.map(e => <option key={e} id={e} value={e}> {e}</option>)}
              </select>
              <div className="text-center"><button onClick={()=>{ addElement(k) }} className="btn btn-outline-success mt-3">Add filter</button></div>
            </div>
          })
        }
      </div>

      {
        // Show buttons
        Object.keys(state.filter).map(key =>{
          return state.filter[key].selected !== null && <button onClick={()=>{deleteOption(key)}} className="btn btn-success m-1 mb-3" key={key}>{key}: {state.filter[key].selected}</button>
        })
      }

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filterUsers(state.machines)}
        />)}
    </div>
  )
};

export default ListMachines;
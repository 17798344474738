import { useEffect, useState } from "react";
import JSONviewer from "../../../helpers/jsonviewer";

const EditTemplateUSer = (props) => {

    const [state, setState] = useState({ status: "loading"});

    useEffect(()=>{
        setState({
            status: "loaded",
            data: props.data,
            callback: props.callback,
            alert: props.alert
        })
    }, [props]);

    const setChangeData = (data) => {
        setState({...state, data:{...state.data, template: data}})
    }

    const saveChange = () => {
        props.callback(state.data);
    }

    if(state.status==="loading"){ return <div></div>}

    return <div>
        <h4 className="mb-4">Edit: {state.data.id}</h4>
        { state.alert && <div className="alert alert-success">Changes saved successfully</div> }
            <JSONviewer value={state.data.template} onChange={ setChangeData } />
            <div className="text-end">
                <button className="btn btn-success mt-3" onClick={ saveChange }>Save Template</button>
            </div>
        </div>
}

export default EditTemplateUSer;
import { useEffect, useState } from "react";
import API from '../../../config/API';
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useParams } from 'react-router-dom';
// https://github.com/vankop/jsoneditor-react

const RenderNodes = () => {

  const {id} = useParams();

  const [state, setState] = useState({
    status : "loading",
    user : {},
    button_enable: true,
    // DATA
    notes: undefined
    // Action
  });

  useState(()=>{
    API.get('/v3/api/auth/user/'+id, {}).then((resp)=>{

      if(resp.status){
        setState({...state, status:"loaded", user:{
          login: resp.data.login,
          currentNodes: resp?.data?.data?.cloud?.parallelJobs || 0,
          nodes: resp?.data?.data?.cloud?.parallelJobs || 0
        }});
      }else{
        setState({status:"error", message: "You don't have permissions or the user don't exit"});
      }
    });

  },[]);

  const differenceBetweenNodes = () => Math.abs(state.user.nodes - state.user.currentNodes);


  const changeRenderNodes = () => {

    const obj = {
      user: id,
      value: parseInt(state.user.nodes),
      notes: state.notes
    }

    setState({...state, button_enable: false});

    API.put('/v3/api/auth/user/parallelJobs', obj).then((resp)=>{

      if(resp?.status){
        setState((oldState)=>{
          return {...oldState, alert: "success", message: "The changes have been completed successfully", button_enable:true, user:{...oldState.user, currentNodes:obj.value }}
        });
      }else{
        setState((oldState)=>{
          return {...oldState, alert: "danger", message: "Error: "+resp?.error, button_enable:true}
        });
        
      }
    });


  }
  
  if(state.status === "error"){ return <div className={"alert alert-danger mt-5"}>{state.message}</div>;}
  if(state.status !== "loaded"){ return <div></div>;}

  return(
    <div>
      <h2>Render Nodes</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <h4>Login</h4>
      <div>{state.user.login}</div>

      <div className="row"> <div className="col-md-6">
        <h4 className="mt-4">Nodes</h4>
        <input type="number" id="nodes" onChange={(e)=>{setState({...state, user:{...state.user, nodes: parseInt(e.target.value) || 0}})}} className="form-control" value={state.user.nodes} ></input>
        {
          state.user.nodes > state.user.currentNodes &&
          <label className="mt-2 greenanyverse">You will add {differenceBetweenNodes()} new render{differenceBetweenNodes()>1?"s":""} node{differenceBetweenNodes()>1?"s":""}</label>
        }
        {
          state.user.nodes < state.user.currentNodes &&
          <label className="mt-2 redanyverse">You will destroy {differenceBetweenNodes()} render{differenceBetweenNodes()>1?"s":""} node{differenceBetweenNodes()>1?"s":""}</label>
        }

        <h4 className="mt-4">Notes</h4>
        <input type="text" id="notes" onChange={(e)=>{setState({...state, notes:e.target.value})}} className="form-control" placeholder="Optional" ></input>
      </div></div>

      {
          state.user.nodes > state.user.currentNodes &&
          <button className="btn btn-success mt-5" onClick={changeRenderNodes} disabled={!(differenceBetweenNodes()!==0 && state.button_enable)}>Create {differenceBetweenNodes()} render{differenceBetweenNodes()>1?"s":""} node{differenceBetweenNodes()>1?"s":""}</button>
      }
      {
          state.user.nodes < state.user.currentNodes &&
          <button className="btn btn-danger mt-5" onClick={changeRenderNodes} disabled={!(differenceBetweenNodes()!==0 && state.button_enable)}>Destroy {differenceBetweenNodes()} render{differenceBetweenNodes()>1?"s":""} node{differenceBetweenNodes()>1?"s":""}</button>
      }
      {
          state.user.nodes === state.user.currentNodes &&
          <button className="btn btn-secondary mt-5" disabled={true}>Waiting for changes</button>
      }



    </div>
  )
};

export default RenderNodes;
import { useEffect, useState } from "react";
import API from '../../config/API';
import {useNavigate} from 'react-router-dom';
import Operables from './operables';
import Loading from '../theme/loading';

const BigQuery = () => {

    const [state, setState] = useState({
        status: "loading",
        filters:{
            select:[],
            groupby:[],
            where:{
                datasets:[],
                users:[]
            }
        },
        data: undefined,
        datasets:[]
    });

    useEffect(()=>{
        const main = async ()=>{
            const datasets = await API.get('/v3/api/datasets');
            const users = await API.get('/v3/api/auth/list', {});
            setState({...state, status:"loaded", datasets, users: users.data});
        }
        main();
    },[]);

    const addColumn = () => {
        const type = document.getElementById("typecolumn").value;
        const column = document.getElementById("column").value;

        let pass = true;
        state.filters.select.forEach(e=>{
            if(e.type === type && e.column === column){ pass = false; }
        })

        if(pass === false){ return; }

        state.filters.select.push({type,column});
        setState({...state});
    }

    const addGroup = () => {
        const name = document.getElementById("columngroup").value;

        let pass = true;
        state.filters.groupby.forEach(e=>{
            if(e === name){ pass = false; }
        })

        if(pass === false){ return; }

        state.filters.groupby.push(name);
        state.filters.select.push({type:undefined,column:name});
        setState({...state});
    }

    const addDataset = () => {
        const name = document.getElementById("datasets").value;

        let pass = true;
        state.filters.where.datasets.forEach(e=>{
            if(e === name){ pass = false; }
        })

        if(pass === false){ return; }

        state.filters.where.datasets.push(name);
        setState({...state});
    }

    const addUsers = () => {
        const user = document.getElementById("users").value;

        let pass = true;
        state.filters.where.users.forEach(e=>{
            if(e === user){ pass = false; }
        })

        if(pass === false){ return; }

        state.filters.where.users.push(user);
        setState({...state});
    }

    const removeColumn = (column, type) => {
        const select = [];

        state.filters.select.forEach(e=>{
            if( !(e.type === type && e.column === column) ){
                select.push(e);
            }
        });

        state.filters.select = select;
        setState({...state});
    }

    const removeGroupby = (toDelete) => {

        const newGroups = state.filters.groupby.filter(e=>!(e===toDelete));

        state.filters.groupby = newGroups;
        setState({...state});

    }

    const removeDataset = (toDelete) => {

        const newWhere = state.filters.where.datasets.filter(e=>!(e===toDelete));

        state.filters.where.datasets = newWhere;
        setState({...state});

    }

    const removeUsers = (toDelete) => {

        const newWhere = state.filters.where.users.filter(e=>!(e===toDelete));

        state.filters.where.users = newWhere;
        setState({...state});

    }

    const isReadyQuery = () => {

        if( 
            // state.filters.select.length &&
            //state.filters.groupby.length &&
            (
                state.filters.select.length || state.filters.groupby.length || state.filters.where.datasets.length || state.filters.where.users.length)
            )
        {
            console.log("true");
            return true;
        }else{
            console.log("false");
            return false;
        }
    }

    const runQuery = () => {
        if(isReadyQuery() === false){return;}

        const obj={};

        // Select
            obj.select = state.filters.select;

        // groupBy
            obj.groupBy = state.filters.groupby;

        // Where
            obj.where = [];

            if(state.filters.where.datasets.length>0){
                obj.where.push({
                    "type" : "in",
                    "column": "datasetId",
                    "value": state.filters.where.datasets
                });
            }

            if(state.filters.where.users.length>0){
                obj.where.push({
                    "type" : "in",
                    "column": "usermongo",
                    "value": state.filters.where.users
                });
            }

        API.post('/v3/api/utils/bigQuery/query', {query:obj}).then(data=>{
            console.log(data);
            if(data?.status === true && data?.data !== undefined){
                setState({...state, error: undefined, data: data.data});
            }else{
                setState({...state, error: "Bad query"});
            }
        })

    }

    if( state.status !== "loaded" ){ return <Loading></Loading> }

    console.log(state);

    return(<div>
          <h2>Big Query</h2>
          <br />
          <div className="row">
            <div className="col-md-3">
                <h5>Select:</h5>
                <div className="row">
                    <div className="col-md-4">
                        <select className="form-control" id="typecolumn">
                            <option value="avg">AVG</option>
                            <option value="sum">SUM</option>
                            <option value="count">COUNT</option>
                        </select>
                    </div>
                    <div className="col-md-8">
                        <select className="form-control" id="column">
                            {Operables.map(o=><option value={o} key={"aggregation"+o}>{o}</option>)}
                        </select>
                    </div>
                </div>
                <div className="text-end mt-3"><input type="button" className="btn btn-success" value="Add column" onClick={addColumn}/></div>
            </div>
            <div className="col-md-3">
                <h5>Group by:</h5>
                <select className="form-control" id="columngroup">
                    {Operables.map(o=><option value={o} key={"groupby"+o}>{o}</option>)}
                </select>
                <div className="text-end mt-3"><input onClick={addGroup} type="button" className="btn btn-success" value="Add group"/></div>
            </div>
            <div className="col-md-3">
                <h5>Where by Dataset:</h5>
                    <select className="form-control" id="datasets">
                        {state.datasets.map(d=> <option key={"where"+d.id} value={d.id} >{d.name} ({d.id})</option>)}
                    </select>
                 <div className="text-end mt-3"><input onClick={addDataset} type="button" className="btn btn-success" value="Add dataset"/></div>
            </div>
            <div className="col-md-3">
                <h5>Where by User:</h5>
                    <select className="form-control" id="users">
                        {state.users.map(d=> <option key={"where"+d._id} value={d._id} >{d.login}</option>)}
                    </select>
                 <div className="text-end mt-3"><input onClick={addUsers} type="button" className="btn btn-success" value="Add users"/></div>
            </div>
          </div>

          <hr />

            <p>SELECT <span>
            {state.filters.select.map(e=><span className="greenanyverseLink" onClick={()=>removeColumn(e.column, e.type)}>{!!e?.type?e.type+"("+e.column+")":e.column} </span>)}
            </span></p>
            <p>FROM machines</p>
            <p>WHERE dataset in ( {state.filters.where.datasets.map(e=><span className="greenanyverseLink" onClick={()=>{removeDataset(e)}}>{e} </span>)}) OR users in ( {state.filters.where.users.map(e=><span className="greenanyverseLink" onClick={()=>{removeUsers(e)}}>{e} </span>)}) </p>
            <p>GROUP BY {state.filters.groupby.map(e=><span className="greenanyverseLink" onClick={()=>removeGroupby(e)}>{e} </span>)}</p>                
            <button className="btn btn-success mt-3" disabled={!isReadyQuery()} onClick={runQuery} >Run query</button>

            {state.error && 
            <div className="alert alert-danger mt-3">{state.error}</div>}

            {state?.data?.length === 0 && 
            <div className="alert alert-warning mt-3">0 rows</div>}

            {(state?.data?.length > 0 &&
            <table className="table table-striped table-hover table-dark mt-3">
                <thead>
                    <tr>
                        {Object.keys(state.data[0]).map(e=><td>{e}</td>)}
                    </tr>
                </thead>
                <tbody>
                    {state.data.map(e=>{
                        return (<tr>
                        {Object.keys(e).map(key=> <th>{e[key]}</th>)}
                        </tr>)
                    })}
                </tbody>
            </table>
            )}

      </div>
    )
  };
  
  export default BigQuery;
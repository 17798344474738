import { useEffect, useState } from "react";

const DeleteThemeUSer = (props) => {

    const [state, setState] = useState({ status: "loading"});

    useEffect(()=>{
        setState({
            id: props.data,
            callback: props.callback,
            alert: props?.alert
        })
    }, [props]);

    const removeit = () => {
        props.callback(state.id);
    }

    if(state.status==="loading"){ return <div></div>}
    
    return <div>
        <h4 className="mb-4">Warning</h4>
        { state.alert && <div className={"alert alert-"+state.alert.type}>{state.alert.message}</div> }
        <div className="alert alert-danger">You are going to delete: {state.id}</div>
            <div className="text-end">
                <button className="btn btn-danger mt-3" onClick={ removeit }>Yes, delete it !</button>
            </div>
        </div>
}

export default DeleteThemeUSer;
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import './style.css';
import Loading from '../../theme/loading';

const ListUsers = () => {

  const [state, setState] = useState({
    status : "loading",
    users: [],
    search: ""
  })

  useEffect(()=>{
    API.get('/v3/api/auth/list/data', {}).then((data)=>{
      if(data.status){
        setState({status:"loaded", users:data.data, search:""});
      }else{
        setState({status:"error", message: "You don't have permissions"});
      }
    });
  },[]);

  const filterUsers = () => {

    return state.users.filter((e)=>{

      return ((e._id+e.login).indexOf(state.search) !== -1?true:false);

    });

  }

  const columns = [
    { name: 'ID', selector: (row) => (<Link to={"/app/user/edit/"+row._id}>{row._id}</Link>), },
    { name: 'Login', selector: row => (<Link to={"/app/user/edit/"+row._id}>{row.login}</Link>), },
    { name: 'Enabled', maxWidth : "30px", selector: row => (<Fragment>{row.data.enabled ? "True" : "False"}</Fragment>) },
    { name: 'Tokens', maxWidth : "30px", selector: (row) => (<Fragment> <Link to={"/app/user/tokens/"+row._id}> Tokens</Link> </Fragment>), },
    { name: 'Add pixels', maxWidth : "30px", selector: (row) => (<Fragment> <Link to={"/app/user/pixels/"+row._id}> Add pixels</Link> </Fragment>), },
    { name: 'Datasets', maxWidth : "30px", selector: (row) => (<Fragment> <Link to={"/app/user/datasets/"+row._id}> Datasets</Link> </Fragment>), },
    { name: 'Add nodes', maxWidth : "30px", selector: (row) => (<Fragment> <Link to={"/app/user/rendernodes/"+row._id}> Add nodes</Link> </Fragment>), },
    { name: 'Password', maxWidth : "200px", selector: (row) => (<Fragment> <Link to={"/app/user/change-password/"+row._id}> Change Password</Link> </Fragment>), },
  ];

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>
      <h2>User list</h2>
      
      <div className="row mt-3 mb-3">
        <div className="col-md-9">
          <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
        </div>
        <div className="col-md-3">
          <Link to="/app/user/add"><button className="btn btn-success form-control">Add user</button></Link>
          <br/>
          <Link to='/app/user/add-param'><p className="text-end greenanyverse mt-3 mb-0" >Add new param</p></Link>
        </div>
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filterUsers(state.users)}
        />)}
    </div>
  )
};

export default ListUsers;
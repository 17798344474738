import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../../components/theme/loading';

const ListUsers = () => {

  const [state, setState] = useState({
    status : "loading",
    users: [],
    search: "",
    filter: {
      selected: "none",
      options: []
    },
    filterGroup: {
      selected: "none",
      value: []
    }
  })

  useEffect(()=>{
    API.get('/v3/api/auth/list/data', {}).then((data)=>{

      if(data.status){

        const [users, permissions, groups] = formatPermissions(data.data);

        setState({...state, status:"loaded", users : users.sort((a,b)=> b.points-a.points), permissions, groups, search:""});

      }else{
        setState({...state, status:"error", message: "You don't have permissions"});
      }

    });
  },[]);


  const formatPermissions = (users) => {
    const newUsers = [...users];
    const permissions = {};
    var groups = [];

    for (let i = 0; i < users.length; i++) {
      let points = 0;

      Object.keys(users[i].data).forEach( e => {

        // Permissions
          const permissionValue = users[i].data[e];

          if( e === "groups"){
            groups = [...groups, ...permissionValue]
          }

          if( e !== "groups"){

            if( permissions[e] === undefined) {
              permissions[e] = [permissionValue];
            }else{
              if( permissions[e].includes( permissionValue ) === false ){
                permissions[e].push(permissionValue);
              }
            }

          }

        // Points
          if( typeof users[i].data[e] === 'boolean') {
            if(e === 'isAdmin' && users[i].data[e] === true){
              points += 10;
            }else if(users[i].data[e]){
              points++
            }
          }
      });

      newUsers[i].points = points;

    }

    return [newUsers, permissions, [...new Set(groups)]];

  }

  const addElement = () => {
    state.filter.options[state.filter.selected] = JSON.parse(document.getElementById("selectValue").value);

    setState({...state});
  }

  const deleteOption = (e) => {
    delete state.filter.options[e];
    setState({...state});
  }


  const addGroup = () => {
    if( state.filterGroup.value.includes(state.filterGroup.selected) ){
      return;
    }
    state.filterGroup.value.push(state.filterGroup.selected);

    setState({...state});
  }

  const deleteGroup = (e) => {
    state.filterGroup.value = state.filterGroup.value.filter(x => x !== e); 
    setState({...state});
  }


  const filterUsers = () => {

    return state.users.filter((u)=>{

      let pass = true;
      Object.keys(state.filter.options).forEach(opt => {

        if( u.data[opt] !== state.filter.options[opt] ){
          pass = false;
        }
      })

      return pass;

    }).filter((u)=>{
      let pass = true;
      state.filterGroup.value.map(g=>{
        if( u.data.groups.includes(g) === false ){
          pass = false;
        }
      })
      return pass;
    });

  }



  /*
    THEME
  */
  const columns = [
    { name: 'ID', selector: row => (<Link to={"/app/user/edit/"+row._id}>{row._id}</Link>), },
    { name: 'Login', selector: row => (<Link to={"/app/user/edit/"+row._id}>{row.login}</Link>), },
    { name: 'Points', selector: (row) =>  row.points, sortable: true },
  ];


  if(state.status !== "loaded"){ return <Loading></Loading> }

  return(
    <div>
      <h2>User list permissions</h2>

      <div className="row mt-4 mb-3">
      <div className="col-md-4">
          <select className="form-control" onChange={(ele)=>{ setState({...state, filter: {...state.filter, selected: ele.target.value}}) }}>
            <option value="none">Filter by permission</option>
            { Object.keys(state.permissions).sort().map(e => <option key={e} value={e}>{e}</option>) }
          </select>
        </div>
        <div className="col-md-4">
          {
            state.filter.selected !== "none" && 
            <select className="form-control" id="selectValue">
              { state.permissions[state.filter.selected].filter(v=>!Array.isArray(v)).map(e => <option key={e} value={JSON.stringify(e)}>{JSON.stringify(e)}</option>) }
            </select>
            }
        </div>
        <div className="col-md-4">
          {
            state.filter.selected !== "none" && 
            <button className="btn btn-success form-control" onClick={addElement}>Add element</button>
            }
        </div>
      </div>

      <div className="row mt-4 mb-3">
        <div className="col-md-4">
          <select className="form-control" onChange={(ele)=>{ setState({...state, filterGroup: {...state.filterGroup, selected: ele.target.value}}) }}>
            <option value="none">Filter by group</option>
            { state.groups.sort().map(e => <option key={e} value={e}>{e}</option>) }
          </select>
        </div>
        <div className="col-md-4">
          {
            state.filterGroup.selected !== "none" && 
            <button className="btn btn-success form-control" onClick={addGroup}>Add element</button>
          }
        </div>
        <div className="col-md-4">
        </div>
      </div>

      <div className="mb-3">
        {Object.keys(state.filter.options).map(e=>
          <button className="btn btn-success m-1" key={e} onClick={ele=>deleteOption(e)}> {e+": "+JSON.stringify(state.filter.options[e])} </button>
        )}
        {state.filterGroup.value.map(e=>
          <button className="btn btn-success m-1" key={e} onClick={ele=>deleteGroup(e)}> {"Group: "+e} </button>
        )}
        
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filterUsers(state.users)}
        />)}
    </div>
  )
};

export default ListUsers;
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { JsonEditor as Editor } from 'jsoneditor-react';
import {Link, useParams} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';

const TokensList = () => {

  const {id} = useParams();

  const [state, setState] = useState({
    status : "loading",
    tokens: [],
    moreinfo: false
  })

  useEffect(()=>{
    loadTokens();
  },[]);

  const loadTokens = () => {
    API.get('/v3/api/auth/tokens/', {}).then((data)=>{
      if(data.status){
        setState({...state, status:"loaded", tokens: data.data, search:""});
      }else{
        setState({...state, status:"error", message: "Error "+data?.error});
      }
    });
  }

  const removeToken = (e) => {

    const token = e.target.id;
    let confirm = window.confirm("Are you sure?");


    if(confirm){

      API.delete('/v3/api/auth/tokens/', {removetoken:token}).then((data)=>{
        if(data.status){
          const tokens = state.tokens.filter(row=>row.token!=token);
          setState({...state, status:"loaded", tokens: tokens});
        }else{
          setState({...state, status:"error", message: "You don't have permissions"});
        }
      });
    }

  }

  const columns = [
    { name: 'Token', maxWidth:"500px", selector: (row) => row.token, },
    { name: 'Created', selector: row => new Date(row.created).toISOString(), },
    { name: 'Last Usage', selector: row => row.lastUsage ? new Date(row.lastUsage).toISOString() : "-" },
    { name: 'IP', selector: row => row?.data?.ip },
    { name: 'Secure', selector: row => row?.data["2FA"] ? "2FA" : "Normal" },
    { name: 'Remove', selector: (row) => (<Fragment><a href="#" id={row.token} onClick={removeToken}>Remove token</a></Fragment>), }
  ];

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>
      <h2>Tokens ({(state?.tokens?.length?state?.tokens?.length:0)})</h2>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}


      {(state.status==='loaded'&&<DataTable
          columns={columns}
          data={state.tokens}
      />)}
    </div>
  )
};

export default TokensList;
import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import Loading from '../../../components/theme/loading';

import Regions from './regions';

const NewInfrastructure = () => {

  const [state, setState] = useState({
    status : "loading",
    customerName : "",
    location: "europe-west1",
  });

  const [wait, setWait] = useState(false);

  const createNewInfrastructure = () => {

    setWait(true);
    
    API.post('/v3/api/admin/serviceInfrastructure', {
      customerName: state.customerName,
      location: state.location
    }).then(data=>{
      setWait(false);
      if(data?.status === true){
        return setState({...state, status:'success', message: 'The new infrastructure has been successfully created.'});
      }
      setState({...state, status:'error', message: data});

    })
  }

  return(
    <div>
      <h2>New infrastructure</h2>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}
      {(state.status==='success'&&<div className="alert alert-success">{state.message}</div>)}
      
      <div className="row">

        <div className="col-md-4 mt-4">

          {(wait?<Loading/>:
          <div>


            <h4>Customer Name: (20 characters max)</h4>
            <input maxlength="20" type="text" className="form-control" onChange={e=>{setState({...state, customerName: e.target.value})}} />

            <h4 className="mt-4">Region:</h4>
            <select className="form-control form-select" defaultValue={state.location}  onChange={e=>{setState({...state, location: e.target.value})}} >
              {Regions.map(e=><option key={e} value={e}>{e}</option>)}
            </select>

            <button
              className="btn btn-success mt-4"
              disabled={state.customerName.length<=4}
              onClick={createNewInfrastructure}
              >Create</button>
            </div>
            )}
        </div>
      </div>

    </div>
  )
};

export default NewInfrastructure;
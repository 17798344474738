import { useEffect, useState } from "react";
import JSONviewer from "../../../helpers/jsonviewer";

const CloneTemplateUSer = (props) => {

    const [state, setState] = useState({ status: "loading"});

    useEffect(()=>{
        setState({
            status: "loaded",
            data: props.data,
            callback: props.callback,
            checkName:  props.checkName,
            alert: props.alert,
            name: ""
        })
    }, [props]);

    const setChangeData = (data) => {
        setState({...state, data:{...state.data, template: data}})
    }

    const saveChange = () => {

        const newData = {...state.data, id: state.name}
        props.callback(newData);
    }

    if(state.status==="loading"){ return <div></div>}
    
    return <div>
        <h4 className="mb-4">Clone from: {state.data.id}</h4>
        <h5>Name:</h5>
        <input type="text" className="form-control mb-4" placeholder="Enter a name" value={state.name} onChange={ (e)=>{ setState({...state, name:e.target.value}) } } />
        { state.alert && <div className={"alert alert-"+state.alert.type}>{state.alert.message}</div> }
            <JSONviewer value={state.data.template} onChange={ setChangeData } />
            <div className="text-end">
                <button className="btn btn-success mt-3" onClick={ saveChange } disabled={!(state.name.length>=3)}>Save new template</button>
            </div>
        </div>
}

export default CloneTemplateUSer;
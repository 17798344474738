import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';
import Loading from "../../theme/loading";

const AddUser = () => {
  const [state, setState] = useState({
    disabled:false,
    status:"loading",
    licenses:[]
  });

  const nav = useNavigate();

  useEffect(()=>{
    API.get('/v3/api/licenses', {}).then((data)=>{
      const licenses_base = data.filter(l=>l.type==="base");

      if( !Array.isArray(data) ){
        setState({...state, status: 'error', message: "Error when try to recover the licenses" });
      }

      setState({...state, status:"loaded", licenses: licenses_base});

    });
  },[]);

  const createUser = () =>{

    const user = document.getElementById("user").value;
    const license = document.getElementById("license").value;

    if(user.length > 3){
      setState({...state, disabled:true});

      API.post('/v3/api/auth/user/', {
        email: user,
        license: license
      }).then( (e)=>{

        if(e.status){
          nav('/app/users/list/');
        }else{
          setState({...state, disabled:false, alert:"danger", message:"An error occurred, maybe the email is already in use."});
        }

      } )

    }else{
      setState({...state, disabled:false, alert:"danger", message:"Minimum four (4) characters are required."});
    }

  }


  if(state.status === "loading"){ return <Loading />; }
  if(state.status === "error"){ return <div className="alert alert-danger">{state?.message}</div>; }

  return(
    <Fragment>
      <h2>Add User</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Login:</h4>
          <input type="text" id="user" className="form-control" placeholder="username@domain.com" />
          <br/><br/>
          <h4>License:</h4>
          <select id="license" className="form-control">
            {state.licenses.map(t => <option value={t.id} key={t.id}>{t.name}</option> )}
          </select>
          <br/><br/>
          <button className="btn btn-success" onClick={createUser} disabled={state.disabled}>Create user</button>
        </div>
      </div>
    </Fragment>
  )
};

export default AddUser;
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';

const ListThemes = () => {

  const [state, setState] = useState({ status : "loading", themes: [], search: "" })


  useEffect(()=>{
    API.get('/v3/api/themes', {}).then((data)=>{
      if(data.status){
        setState({status:"loaded", themes:data.data, search:""});
      }else{
        setState({status:"error", message: "You don't have permissions"});
      }
    });
  },[]);

  const filtersThemes = () => {

    return state.themes.filter((e)=>{

      return ((e._id+e.name).indexOf(state.search) !== -1?true:false);

    });

  }

  const columns = [
    { name: 'ID', selector: (row) => (<Link to={"/app/themes/edit/"+row.id}>{row.id}</Link>), },
    { name: 'Name', selector: row => (<Link to={"/app/themes/edit/"+row.id}>{row.name}</Link>), },
    { name: 'Edit', selector: (row) => (<Fragment><Link to={"/app/themes/edit/"+row.id}>Edit theme</Link></Fragment>), },
    { name: 'Testing', selector: (row) => (<Fragment><Link to={"/app/mailing/sendtest/"+row.id}>Send email</Link></Fragment>), }
  ];

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>
      <h2>Themes list</h2>
      
      <div className="row mt-3 mb-3">
        <div className="col-md-9">
          <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
        </div>
        <div className="col-md-3">
          <Link to="/app/themes/add"><button className="btn btn-success form-control">Add theme</button></Link>
        </div>
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filtersThemes(state.themes)}
        />)}


    </div>
  )
};

export default ListThemes;
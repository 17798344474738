import './style.css';

import {Link, useNavigate, useLocation} from 'react-router-dom';
import API from '../../../config/API';
import { useState } from 'react';

const Menu = (info) => {

  const nav = useNavigate();
  const location = useLocation();
  const path = (location.pathname.split("/").filter(e=>!!e.length).length===1?"main":location.pathname);

  const [state, setState] = useState({menu:[
    {to:"#", name:"Users", sub:[
      {to:"/app/users/list", name:"List"},
      {to:"/app/users/permissions", name:"Permissions"},
      {to:"/app/user/sessions", name:"Sessions"}
    ], drowpdown: false},
    {to:"#", name:"Architecture", sub:[
      {to:"/app/machines/list", name:"List machines"},
      {to:"/app/machines/topview", name:"TopView (machines)"},
      {to:"/app/machines/metadata", name:"Metadata (machines)"},
      {to:"/app/infrastructure/new", name:"New infrastructure"}
    ], drowpdown: false},
    {to:"#", name:"Mailing", sub:[
      {to:"/app/themes", name:"Themes"},
      {to:"/app/mailing", name:"Latest emails"},
      {to:"/app/configuration/edit/emails", name:"Config"},
    ], drowpdown: false},
    {to:"#", name:"Logs", sub:[
      {to:"/app/logs/job", name:"Job"},
      {to:"/app/logs/machine", name:"Machine"},
      {to:"/app/logs/requestBySize", name:"Request By Size"},
      {to:"/app/logs/requestByTime", name:"Request By Time"}
    ], drowpdown: false},
    {to:"#", name:"Config", sub:[
      {to:"/app/configuration", name:"Configuration"},
      {to:"/app/dynamicresource", name:"Dynamic Resources"},
      {to:"/app/licenses", name:"Licenses"}
    ], drowpdown: false},
    {to:"#", name:"Utils", sub:[
      {to:"/app/bigquery", name:"Big Query"},
      {to:"/app/errorjobs", name:"Error Jobs"}
    ], drowpdown: false}
  ]});

  const clickMenu = (name) => { 
    const i = state.menu.findIndex(e=>(e.name===name));
    state.menu[i].drowpdown = !state.menu[i].drowpdown;

    setState({...state});
  }


  const logout = () => {
    API.remove_token();
    nav('/');
  }

  return(
    <div className="compMenu">

      <nav className="navbar navbar-expand-lg navbar-dark">
        <button className="navbar-toggler form-control" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <div id="telita">
            <a className="navbar-brand" href="#">Menú</a>
            <span className="navbar-toggler-icon"></span>
          </div>
        </button>
        <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div className="nav flex-column">
            {state.menu.map((e)=>{
              if(!e?.sub){
                return <Link
                  key={e.name}
                  className={"nav-link mb-2 "+(e.to.startsWith(path)&&"currentlink")}
                  to={e.to}>{e.name}</Link>
              }
              
              return <div
                key={e.name}
                className='submenu'>
                  <Link
                    onClick={()=>clickMenu(e.name)}
                    className={"nav-link mb-2 "+(e.to.startsWith(path)&&"currentlink")}
                    to={e.to}>{e.name} <i className='arrow'></i></Link>
                <div style={{display: e.drowpdown?'block':"none" }}>
                {e.sub.map(e2=>{
                  return <div key={"sub"+e2.name} className='submenulink'><Link key={e2.name} className={"nav-link mb-2 "+(e2.to.startsWith(path)&&"currentlink")+" "+(e2?.class?e2.class:"")} to={e2.to}>{e2.name}</Link></div>
                })}
                </div>
              </div>
            })}
            <div className="nav-link mb-2 logout" onClick={logout}>Logout</div>
          </div>
        </div>
      </nav>

    </div>
  );

};

export default Menu;
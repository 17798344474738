import {createContext, useState} from 'react';

const Contexto = (props) => {

    const [statecnx, setStateCnx] = useState({user: props.data});

    return(
        <EntornoGlobal.Provider value={[statecnx, setStateCnx]}>
            {props.children}
        </EntornoGlobal.Provider>
    )

}

export default Contexto;
export const EntornoGlobal = createContext();
import { JsonEditor as Editor } from 'jsoneditor-react';
import { useEffect, useState, useRef } from "react";

const Jsonparser = (props) =>{
   const jsonEditorRef = useRef();
    const [state, setState] = useState({data:props.value, config: props.config || {}});

    const updateData = () => {
        
    }
    useEffect(
        () => {
            const editor = jsonEditorRef && jsonEditorRef.current && jsonEditorRef.current.jsonEditor;
            if(editor && props.value){ editor.update(props.value); }
        },
        [jsonEditorRef, props.value]
      );

    return <Editor
      value={state.data}
      ref={jsonEditorRef}
      onChange={props.onChange}
      {...state.config}
    />
}

export default Jsonparser;
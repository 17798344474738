import { Fragment, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {slugify} from '../../../config/funcaux';

const LicenseAdd = () => {
  const [state, setState] = useState({disabled:false, data:{
    name:"",
    description:"",
    type: "normal",
    erasable:false,
    editable:false,
    template:{}
  }});
  const nav = useNavigate();

  const createLicense = () =>{
    
    API.post('/v3/api/licenses/', state.data).then((data)=>{
      nav('/app/licenses/');
    });

  }

  const validToSend = () => {

    if( 
      state.data.name.length >= 3 &&
      state.data.description.length >= 3
    ){
      return true;
    }else{
      return false;
    }

  }

  const updateData = (json) => {
    setState({...state, data:{...state.data, template: json}});
  }

  return(
    <Fragment>
      <h2>Add License</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Name:</h4>
          <input type="text" id="name" className="form-control" onChange={(e)=>{ setState({...state, data:{...state.data, name: slugify(e.target.value)}}) }}/>
          <p className="ms-2 mt-2 fw-lighter">{(state.data.name.length>0?"ID:":"")} {slugify(state.data.name)}</p>
          <h4 className="mt-4">Description:</h4>
          <input type="text" id="description" className="form-control" onChange={(e)=>{ setState({...state, data:{...state.data, description: e.target.value}}) }}/>
          <h4 className="mt-4">Type:</h4>
          <select className="form-select" id="type" value={state.data.type}  onChange={(e)=>{ setState({...state, data:{...state.data, type: e.target.value}}) }}>
            <option value={"base"}>Base</option>
            <option value={"normal"}>Normal</option>
            <option value={"boost"}>Boost</option>
          </select>

          <h4 className="mt-4">Permissions :</h4>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="checkEditable" checked={state.data.editable} onChange={e=>setState({...state,data:{...state.data, editable:e.target.checked}})} />
            <label className="form-check-label">Editable</label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="checkEditable" checked={state.data.erasable} onChange={e=>setState({...state,data:{...state.data, erasable:e.target.checked}})}   />
            <label className="form-check-label">Erasable</label>
          </div>

        </div>
        <div className="col-md-6">
        <Editor
            value={state.data.template}
            onChange={updateData}
          />
          <br/><br/>
          <button className="btn btn-success" onClick={createLicense} disabled={!validToSend()}>Create license</button>

        </div>
      </div>
    </Fragment>
  )
};

export default LicenseAdd;
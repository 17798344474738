import { Fragment, useEffect, useState } from "react";
import API from "../../../config/API";
import Loading from "../../theme/loading";
import JSONviewer from "../../../helpers/jsonviewer";
import EditTheme from './edit';
import CloneTheme from './clone';
import DeleteTheme from './delete';

const ThemeUSer = () => {

    const [state, setState] = useState({
        status: 'loading',
        view: { status: false, id: undefined, alert: false},
        data: [],
        time: +new Date()
    });

    useEffect(()=>{
        API.get('/v3/api/utils/userTemplates/', {}).then((data)=>{
            if( typeof data === 'string'){
                return setState({...state, status: "error", message: data});
            }

            if( !Array.isArray(data) ){
                return setState({...state, status: "error", message: "The attribute templates is not an array."});
            }

            setState({
                ...state,
                status: "loaded",
                data: data
            });
          });

    }, [state.time]);

    const loadTemplate = async (key) => {
        const q = await API.get('/v3/api/utils/userTemplates/'+key, {});
        if( typeof q === 'string'){
            return setState({...state, status: "error", message: q});
        }

        return q;
    }

    const loadEdit = async (key) =>{
        const data = await loadTemplate(key);
        setState({...state, view: {status: 'edit', id: key, data, alert: false}});
    }

    const saveEdit = async (data) => {

        const q = await API.put('/v3/api/utils/userTemplates/'+data.id, {template: data.template}).then(data=>{
            if( typeof data === 'string'){
                return setState({...state, status: "error", message: data});
            }

            const view = {...state.view, data, alert: {type: "success"} };
            setState({...state, view, time: +new Date()});
        });
    }

    const loadClone = async (key) =>{
        const data = await loadTemplate(key);
        setState({...state, view: {status: 'clone',  id: key, data, alert: false}});
    }

    const saveClone = async (data) => {

        const q = await API.post('/v3/api/utils/userTemplates/'+data.id, {template: data.template}).then(data=>{
            if( typeof data === 'string'){
                return setState({...state, status: "error", message: data});
            }

            const view = { status: false, id: undefined, alert: false};
            setState({...state, view, time: +new Date()});
        });
    }

    const loadDelete = (key) =>{
        setState({...state, view: {status: 'delete', id: key, alert: false}});
    }

    const saveDelete = async (data) => {

        const q = await API.delete('/v3/api/utils/userTemplates/'+data).then(data=>{
            if( typeof data === 'string'){
                return setState({...state, status: "error", message: data});
            }

            const view = { status: false, id: undefined, alert: false};
            setState({...state, view, time: +new Date()});
        });
    }

    if(state.status === "loading"){ return <div><Loading /></div> }
    if(state.status === "error"){ return <div className="alert alert-danger">{state.message}</div> }

    return <div>
        <div className="row">
            <div className="col-md-2 border-end p-3">
                <h5>Templates</h5>
                <hr/>
                {state.data.map(e=> <div className="mt-3" key={"options"+e.id}>
                    <p className="greenanyverse">{e.id}</p>
                    <p>
                        <span className="fakeLink" onClick={()=>loadEdit(e.id)}>Edit</span> - <span className="fakeLink" onClick={()=>loadClone(e.id)}>Clone</span> {e?.erasable ? <Fragment><span> - </span><span className="fakeLink"onClick={()=>loadDelete(e.id)}>Delete</span></Fragment> : ""}
                    </p>
                    <hr/>
                </div>)}
            </div>
            <div className="col-md-10">
                { state.view.status === "edit" && <EditTheme data={state?.view?.data} alert={state?.view?.alert} callback={saveEdit} /> }
                { state.view.status === "clone" && <CloneTheme data={state?.view?.data} alert={state?.view?.alert} callback={saveClone} /> }
                { state.view.status === "delete" && <DeleteTheme data={state?.view?.id} alert={state?.view?.alert} callback={saveDelete}/> }
            </div>
        </div>
    </div>;
}

export default ThemeUSer;
import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';

const LicenseList = () => {

  const [state, setState] = useState({ status : "loading", licenses: [], search: "" })


  useEffect(()=>{
    API.get('/v3/api/licenses', {}).then((data)=>{
        setState({status:"loaded", licenses:data, search:""});
    });
  },[]);

  const filterLicenses = () => {

    return state.licenses.filter((e)=>{

      return ((e.id+e.name).indexOf(state.search) !== -1?true:false);

    });

  }

  const columns = [
    { name: 'ID', selector: (row) => (<Link to={"/app/licenses/get/"+row.id}>{row.id}</Link>), },
    { name: 'type', selector: (row) => (<Link to={"/app/licenses/get/"+row.id}>{row.type}</Link>), },
    { name: 'name', selector: (row) => (<Link to={"/app/licenses/get/"+row.id}>{row.name}</Link>), },
    { name: 'description', selector: (row) => (<Link to={"/app/licenses/get/"+row.id}>{row.description}</Link>), },
    { name: 'Edit', selector: (row) => ( row.editable?<Link to={"/app/licenses/edit/"+row.id}>Edit</Link>:<></> ), },
    { name: 'Delete', selector: (row) => ( row.erasable?<span title="Please, contact the administrator">Delete</span>:<></> ), }
  ];

  if(state.status === "loading"){ return <Loading/> }

  return(
    <div>
      <h2>Licenses list</h2>
      
      <div className="row mt-3 mb-3">
        <div className="col-md-9">
          <input type="text" className="form-control" onChange={(e)=>{ setState({...state, search: e.target.value})}} placeholder="Search..." />
        </div>
        <div className="col-md-3">
          <Link to="/app/licenses/add"><button className="btn btn-success form-control">Add License</button></Link>
        </div>
      </div>

      {(state.status==='error'&&<div className="alert alert-danger">{state.message}</div>)}

      {(state.status==='loaded'&&<DataTable
            columns={columns}
            data={filterLicenses(state.licenses)}
        />)}


    </div>
  )
};

export default LicenseList;
import { Fragment, useEffect, useState } from "react";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';
import { useParams } from 'react-router-dom';

const Datasets = () => {

  const [state, setState] = useState({
    status : "loading",
    users: [],
    datasets: null,
    batchs: null
  });

  const [waiting, setWaiting] = useState({
    batchs: false,
    jobs: false
  });

  const {id} = useParams();
  

  useEffect(()=>{
    API.get('/v3/api/admin/datasets/'+id, {}).then((data)=>{
      if(Array.isArray(data)){
        setState({...state, status:"loaded", datasets:data});
      }else{
        setState({...state, status:"error", message: "You don't have permissions"});
      }
    });
  },[]);

  const loadBatchs = (id) => {
    setWaiting({...waiting, batchs: true});
    API.get('/v3/api/admin/batchexecs/'+id, {}).then((data)=>{
      if(Array.isArray(data)){
        setState({...state, batchs:data, jobs: []});
      }else{
        setState({...state, status:"error", message: "You don't have permissions"});
      }
      setWaiting({...waiting, batchs: false});
    });
  }


  const loadJobs = (id) => {
    setWaiting({...waiting, jobs: true});
    API.get('/v3/api/admin/jobs/'+id, {}).then((data)=>{
      if(Array.isArray(data)){
        setState({...state, jobs: data});
      }else{
        setState({...state, status:"error", message: "You don't have permissions"});
      }
      setWaiting({...waiting, jobs: false});
    });
  }


  if(state.status === "loading"){ return <Loading/> }

  return( 
    <div>
      <h2 className="mb-5">Datasets ({id})</h2>
      <div className="row">
      <div className="col-md-4">
        <h3>Datasets</h3>
        {state.datasets.map(d=><div className="pointerPlus" key={d.id} onClick={()=>loadBatchs(d.id)}>{(new Date(d.creation)).toISOString().slice(0,10)} - {d.name}</div>)}
      </div>
      <div className="col-md-4">
        <h3>Batchs</h3>
        {(waiting.batchs===true) && <Loading/>}
        {(Array.isArray(state.batchs) && waiting.batchs === false) && state.batchs.map(d=><div className="pointerPlus" key={d.id} onClick={()=>loadJobs(d.id)}>{(new Date(d.creation)).toISOString().slice(0,10)} - {d.name} ({d.state})</div>)}
      </div>
      <div className="col-md-4">
        <h3>Jobs</h3>
        {(waiting.jobs===true) && <Loading/>}
        {(Array.isArray(state.jobs) && waiting.jobs === false ) && state.jobs.map(d=><Link to={"/app/logs/job/"+d.id+"/"+d.creation.slice(0,10)}><div key={d.id} onClick={()=>loadBatchs(d.id)}>{d.id} ({d?.type})</div></Link>)}
      </div>
      </div>

    </div>
  )
};

export default Datasets;
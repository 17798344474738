import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useParams} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import Loading from '../../theme/loading';

const SendTest = () => {
  const {id} = useParams();
  const [state, setState] = useState( {
    status: "loaded",
    id: id,
    data:{"name":"example", "body": "test"},
    alertType: undefined,
    alertText: undefined } );


  const sendEmail = () =>{

    if(state.id === ""){ return alert("ID is required"); }

    console.log(state);

    const obj = {
      theme: state.id,
      bodyOBJ: state.data
    }

    API.post('/v3/api/mailing/testSend', obj).then((data)=>{
      if( data?.status === true){
        setState({...state, alertType:"success", alertText: "The test email with the "+state.id+" theme has been sent"})
      }else{
        setState({...state, alertType:"danger", alertText: data?.error || "Undefined error"})
      }

    });

  }

  const updateData = (json) => {
    setState({...state, data:json});
  }

  if(state.status === "loading"){ return <Loading/> }

  return(
    <Fragment>
      <h2>Theme testing</h2>
      {state?.alertType && <div className={"alert alert-"+state.alertType+" mt-5"}>{state.alertText}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Theme:</h4>
          <input type="text" id="IDconfig" value={state.id} className="form-control" disabled/>
          <br/><br/>
          <Editor
            value={state.data}
            onChange={updateData}
          />
          <br/><br/>
          <button className="btn btn-success" onClick={sendEmail}>Send Email</button>
        </div>
      </div>
    </Fragment>
  )
};

export default SendTest;
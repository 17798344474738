import { Fragment, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import {Link} from 'react-router-dom';
import API from '../../../config/API';
import Loading from '../../theme/loading';

const ListMails = () => {

  const [state, setState] = useState({
    status: "loading",
    mails: [],
    search: "",
    statusFilter: "all",
    statusWithErrors: 0,
    dateFilter: (new Date(((+new Date()) - 2592000000)).toISOString())
    });


  useEffect(()=>{

    API.get('/v3/api/mailing', {from:state.dateFilter}).then((data)=>{

      let countErrors = 0;
      data.data.forEach(e=>{if(e.status===false){countErrors++}})

      setState({...state, status:"loaded", statusWithErrors: countErrors, mails:data.data});

    });
  },[]);

  const filterData = () => {

    return state.mails.filter((e)=>{

      if( state.statusFilter !== "all" ){
        const value = String(e?.status === undefined ? true : e.status )
        if( state.statusFilter !== value){
          return false;
        }
      }

      return ((e._id+e.to+e.from+e.subject).indexOf(state.search) !== -1?true:false);

    });

  }

  const newSearch = (e) => {

   const date = new Date(e.target.value)
   if(isNaN( +date )){ return; } 
   const dateISO = date.toISOString();

   API.get('/v3/api/mailing', {from:dateISO}).then((data)=>{
    
      let countErrors = 0;
      data.data.forEach(e=>{if(e.status===false){countErrors++}})
      setState({...state, mails:data.data, statusWithErrors: countErrors, dateFilter: dateISO});
    });

  }
  
  const columns = [
    { name: 'ID', selector: (row) => (<Link to={"/app/configuration/edit/"+row._id}>{row._id}</Link>), },
    { name: 'To', selector: (row) => row.to, sortable: true },
    { name: 'Subject', selector: (row) => row.subject, sortable: true },
    { name: 'Created', selector: (row) => row.created.substring(0,16).replace("T"," "), sortable: true },
    { name: 'Sent', selector: (row) => String( (row?.status === undefined ? true : row.status ) ), sortable: true },
  ];

  if(state.status === "loading"){ return <Loading/> }
  console.log(state);


  return(
    <div>
      <h2>Mailing <span className="h6">{state.mails.length} mails since {state.dateFilter.substring(0,10)}</span></h2>
      
      {(state?.statusWithErrors>0) && <div className={"alert alert-danger mt-5"}>Some error has caused a failure in sending {state.statusWithErrors} email/s within the last {Math.floor(((new Date()) - (new Date(state.dateFilter))) / (1000 * 60 * 60 * 24))} days</div>}

      <div className="row mt-3 mb-3">
        <div className="col-md-8">
            <input type="text" className="form-control" placeholder="Search..." onChange={(e)=>{ setState({...state, search: e.target.value})}} />
        </div>
        <div className="col-md-2">
            <select id="statusInput" className="form-control" onChange={(e)=>{ setState({...state, statusFilter: e.target.value})}} >
              <option value="all">Status: All</option>
              <option value="true">Status: Sent correctly</option>
              <option value="false">Status: Errors</option>
            </select>
        </div>
        <div className="col-md-2">
            <input type="date" className="form-control" id="textdatafilter" onChange={newSearch} value={state.dateFilter.substring(0,10)} />
        </div>
      </div>

      {/*(state?.status==='error'&&<div className="alert alert-danger">{state.message}</div>)*/}

      {(state?.status==='loaded'&&<DataTable
            columns={columns}
            data={filterData(state.mails)}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100, 250, 500]}
        />)}


    </div>
  )
};

export default ListMails;
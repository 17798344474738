import { Fragment, useEffect, useState } from "react";
import API from '../../../config/API';
import {useNavigate, useParams} from 'react-router-dom';
import { JsonEditor as Editor } from 'jsoneditor-react';
import {slugify} from '../../../config/funcaux';
import Loading from "../../theme/loading";

const LicenseGet = () => {
  const [state, setState] = useState({disabled:false, data:{}, status: 'loading' });
  const {id} = useParams();

  const nav = useNavigate();

  useEffect(()=>{

    API.get('/v3/api/licenses/'+id).then((data)=>{

      if(typeof data === "string"){
        return setState({...state, alert:"danger", message:data, status: "error"});
      }

      return setState({...state, data:data.data, status: "loaded"})
    });

  }, []);

  if( state.status === "error" ){
    return <div>
       <h2>View License</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      </div>;
  }

  if( state.status !== "loaded" ){ return <Loading></Loading>; }

  return(
    <Fragment>
      <h2>Get License</h2>
      {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
      <br />
      <div className="row">
        <div className="col-md-6">
          <h4>Name:</h4>
          <input type="text" id="name" className="form-control" value={state.data.name} />
          <p className="ms-2 mt-2 fw-lighter">ID: {state.data.id}</p>
          <h4 className="mt-4">Description:</h4>
          <input type="text" id="description" className="form-control" value={state.data.description} />
          <h4 className="mt-4">Type:</h4>
          <select className="form-select" id="type" value={state.data.type} >
            <option value={"base"}>Base</option>
            <option value={"normal"}>Normal</option>
            <option value={"boost"}>Boost</option>
          </select>

          <h4 className="mt-4">Permissions :</h4>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="checkEditable" checked={state.data.editable} />
            <label className="form-check-label">Editable</label>
          </div>

          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="checkEditable" checked={state.data.erasable} />
            <label className="form-check-label">Erasable</label>
          </div>

        </div>
        <div className="col-md-6">
        <Editor
            value={state.data.template}
            mode="view"
          />

        </div>
      </div>
    </Fragment>
  )
};

export default LicenseGet;
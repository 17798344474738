
const operables = [
    "cores",
    "ram",
    "typeMachine",
    "subTypeMachine",
    "status",
    "preemptible",
    "preempted",
    "withGPU",
    "usermongo",
    "datasetId",
    "batchId",
    "region",
    "durationSeconds",
    "time_download_seconds",
    "time_render_seconds",
    "time_upload_seconds",
    "totalMachineCost",
    "pixels",
    "price_gbpixel"
];

export default operables;